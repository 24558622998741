import { Button, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { EnumRetornoApiBase } from 'data/api/base/api-base-response';
import { useGetPessoaById } from 'data/api/gestao/pessoa/get-pessoa-by-id';
import { usePutAtualizarPessoa } from 'data/api/gestao/pessoa/put-atualizar-pessoa';
import { usePostImagemBase64 } from 'data/api/imagem/post-imagem';
import { isEmpty } from 'lodash';
import { PessoaModel } from 'model/api/gestao/pessoa/pessoa-model';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { imagemForUpload } from 'utils/imagem-for-upload';
import { newGuid } from 'utils/new-guid';
import { picker } from 'utils/picker';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { FormEditarPessoa } from 'views/components/form/master/editar-pessoa/form-editar-pessoa';
import { DefaultFormRefs } from 'views/components/form/utils';
import { SalvarEditarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { AccordionConfiguracoesSenha } from './accordion-configuracoes-senha/accordion-configuracoes-senha';
import { useStyles } from './configuracoes-editar-styles';
import { AccordionConfiguracoesInfo } from './accordion-configuracoes-info/accordion-configuracoes-info';

export const ConfiguracoesEditar = () => {
    const classes = useStyles();

    const { showToast } = useToastSaurus();

    const { putAtualizarPessoa, carregando: carregandoPut } = usePutAtualizarPessoa();
    const { getPessoaById, carregando: carregandoGet } = useGetPessoaById();
    const [preenchendoTela, setPreechendoTela] = useState<boolean>(false);
    const { theme } = useThemeQueries();
    const [openAccordion, setOpenAccordion] = useState<boolean>(true);
    const { postImagemBase64 } = usePostImagemBase64();
    const { usuario,  logarIntegracao, carregando: carregandoLogar } = useSessaoAtual();

    const id = usuario?.Id ?? ''

    const carregando = carregandoGet || carregandoPut || carregandoLogar || preenchendoTela;

    const cadPessoaFormRef = useRef<DefaultFormRefs<PessoaPostModel>>(null);
    const refPessoaModel = useRef<PessoaModel>(new PessoaModel());
    const [pessoaForm, setPessoaForm] = useState<PessoaPostModel>(
        new PessoaPostModel(),
    );

    const recarregarForm = useCallback((model: PessoaPostModel) => {
        cadPessoaFormRef.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(pessoaForm);
    }, [pessoaForm, recarregarForm]);

    const getPessoaByIdWrapper = useCallback(async () => {
        const res = await getPessoaById(id);

        if (res.erro) throw res.erro;

        const ret = res.resultado?.data as PessoaModel;

        refPessoaModel.current = ret;

        const revenda = picker<PessoaPostModel>(ret, new PessoaPostModel());

        return revenda;
    }, [getPessoaById, id]);

    const preencherTela = useCallback(async () => {
        try {
            setPreechendoTela(true);
            const revenda = await getPessoaByIdWrapper();
            setPessoaForm(revenda);
        } catch (e: any) {
            showToast('error', e.message);
        } finally {
            setPreechendoTela(false);
        }
    }, [getPessoaByIdWrapper, showToast])

    useEffect(() => {
        preencherTela()
    }, [getPessoaByIdWrapper, preencherTela, showToast]);

    const enviarImagem = useCallback(
        async (imagemUrl: string) => {
            let imagem = '';

            const imgUpload = imagemForUpload(imagemUrl);
            if (imgUpload.length > 0) {
                const retImagem = await postImagemBase64(
                    imgUpload,
                    `licenciamento/pessoas/${usuario?.Id}/`,
                    newGuid(),
                );
                if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
                    throw new Error('Erro ao processar  a Imagem selecionada.');
                }
                if (retImagem.resultado?.data.data.status === 2) {
                    throw new Error(
                        'Erro ao processar a Imagem selecionada.Detalhe: ' +
                        retImagem.resultado?.data?.data?.retorno,
                    );
                }
                imagem =
                    retImagem.resultado?.data?.data?.url_blob +
                    '?timestamp=' +
                    new Date().getTime();
            }

            return imagem.length > 0 ? btoa(imagem) : imagem;
        },
        [postImagemBase64, usuario?.Id],
    );

    const saveNewPessoa = useCallback(
        async (model: PessoaPostModel) => {
            if (!isEmpty(model.urlImagem))
                model.urlImagem = await enviarImagem(model.urlImagem);

            const ret = await putAtualizarPessoa(model);

            if (ret.erro) {
                throw ret.erro;
            }
        },
        [enviarImagem, putAtualizarPessoa],
    );

    const handleSubmit = useCallback(
        async (model: PessoaPostModel) => {
            try {
                const NovoPessoaToCreate = picker<PessoaPostModel>(
                    model,
                    refPessoaModel.current,
                );

                await saveNewPessoa(NovoPessoaToCreate);
                await logarIntegracao(NovoPessoaToCreate.id, NovoPessoaToCreate.cpfCnpj)

                showToast('success', 'Usuário Atualizado!');
                return true;
            } catch (e: any) {
                showToast('error', e.message);
                cadPessoaFormRef.current?.resetForm();
                return false;
            }
        },
        [logarIntegracao, saveNewPessoa, showToast],
    );

    const formPessoaIndicacao = useMemo(() => (
        <AccordionSaurus
            labelPrimary="Dados do Agente Externo"
            tipoExpand="bold"
            noPaperRoot={false}
            heightDivider={2}
            showDivider={openAccordion}
            colorDivider={theme.palette.primary.main}
            colorExpand={theme.palette.primary.main}
            expanded={openAccordion}
            onChange={() => setOpenAccordion((prev) => !prev)}
        >
            <div className={classes.content}>
                <div
                    className={classNames(
                        classes.contentForms,
                        carregando ? classes.contentFormsLoading : undefined,
                    )}
                >
                    <FormEditarPessoa
                        ref={cadPessoaFormRef}
                        onSubmit={handleSubmit}
                        loading={carregando}
                        showLoading={carregando}
                    />
                </div>
                <div className={classes.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent='flex-end'>
                                <Grid item xs={12} md={4}>
                                    <Button
                                        disabled={carregando}
                                        onClick={() => {
                                            cadPessoaFormRef.current!.submitForm();
                                        }}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                    >
                                        <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </AccordionSaurus>
    ), [carregando, classes.acoes, classes.content, classes.contentForms, classes.contentFormsLoading, handleSubmit, openAccordion, theme.palette.primary.main])

    const accordionConfiguracoesSenha = useMemo(() => (
        <AccordionConfiguracoesSenha preencherTela={preencherTela} model={pessoaForm} />
      ), [pessoaForm, preencherTela])

    const accordionInfo = useMemo(() => (
        <AccordionConfiguracoesInfo id={pessoaForm.id} tipoPessoa={pessoaForm.tipo}/>
    ), [pessoaForm.id, pessoaForm.tipo])

    return (
        <Grid container spacing={2} className={classes.defaultContainer}>
            <Grid item xs={12}>
                {formPessoaIndicacao}
            </Grid>
            <Grid item xs={12}>
                {accordionConfiguracoesSenha}
            </Grid>
            <Grid item xs={12}>
                {accordionInfo}
            </Grid>
        </Grid>
    );
}