
import { useMemo } from 'react';
import { useValidationYupDocumento, useValidationYupEmail, useValidationYupFone } from 'views/components/form-validations';
import * as Yup from 'yup';

export const useFormIndicacaoDadosValidation = () => {

  const { documentoYup } = useValidationYupDocumento(false)
  const telefoneYup = useValidationYupFone()
  const { emailYup } = useValidationYupEmail()

  const FormIndicacaoDadosValidationYup = useMemo(() => {
    return Yup.object().shape({
      contato: Yup.string().required('O nome é obrigatório'),
      cpfCnpj: documentoYup(),
      telefone: telefoneYup(),
      email: emailYup(),
    });
  }, [documentoYup, emailYup, telefoneYup])

  return {
    FormIndicacaoDadosValidationYup,
  }
}

