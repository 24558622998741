import { Box, Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import { CardIndicacaoProps } from './card-indicacao-props';
import { DefaultCard, useDefaultCardStyles } from '../components/default-card';
import { phoneMask } from 'utils/phone-mask';
import { useMemo } from 'react';
import { formatarCEP } from 'utils/formatar-cep';
import { useThemeQueries } from 'views/theme';
import { Informacoes } from 'model/api/gestao/indicacao/indicacao-model';
import { toDateString } from 'utils/to-date';
import { isEmpty } from 'lodash';
import { AvancarIcon } from 'views/components/icons';
import { useStyles } from './card-indicacao-styles';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { StatusGeralIndicacoMock } from 'data/mocks/status-geral-indicacao-mock';
import { useRetornarCorStatus } from 'services/app/use-cases/retornar-cor-status';

export const CardIndicacao = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardIndicacaoProps) => {

  const { theme, isMobile } = useThemeQueries()

  const classes = useDefaultCardStyles();
  const indicacoesStyles = useStyles();
  const {retornarCorStatus} = useRetornarCorStatus();

  const retornaEndereco = useMemo(() => {
    const { bairro, cep, municipio, numero, complemento, logradouro, uf } = model.cliente?.informacoes ?? new Informacoes()

    if (isEmpty(model.cliente?.informacoes)) {
      return ''
    }

    return `${logradouro ?? ''}, ${numero ?? 0} - ${bairro ?? ''}${municipio ? `, ${municipio}` : ''}${uf ? ` - ${uf}` : ''}${cep ? `, ${formatarCEP(cep ?? '')}` : ''}${complemento ? `, ${complemento}` : ''}`
  }, [model.cliente?.informacoes])

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id);
        }}
      >
        {isMobile ? (
          <Box p={1} width="100%">
            <Grid container spacing={1}>
              <Box width="100%">
                <Box display={"flex"} justifyContent={"space-between"} alignItems="center" paddingX={1}>
                  <Typography>
                    <Box fontWeight={600} color={theme.palette.grey[600]}>
                      {toDateString(model.data)}
                    </Box>
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography className={indicacoesStyles.statusText} style={{ backgroundColor: retornarCorStatus(model.status) }}>
                      {StatusGeralIndicacoMock.find(x => x.Key === model.status)?.Value}
                    </Typography>
                    <Tooltip title="Visualizar Dados da Indicação" arrow>
                      <Box display="flex" className={indicacoesStyles.btnAvancar}>
                        <AvancarIcon tipo="BUTTON" />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={5} md={3}>
                <Typography className={indicacoesStyles.text}>
                  CPF/CNPJ
                </Typography>
                <Typography className={indicacoesStyles.subText}>
                  {formatarCPFCNPJ(model.cliente?.cpfCnpj)}
                </Typography>
              </Grid>
              <Grid item xs={7} md={3} className={indicacoesStyles.formartText}>
                <Typography className={indicacoesStyles.text}>
                  Razão Social
                </Typography>
                <Typography variant='body2' style={{ fontWeight: 700, fontSize: '13px' }}>
                  {model.cliente?.nomeComercial}
                </Typography>
              </Grid>
              <Grid item xs={5} md={3} style={{ display: 'flex', justifyContent: 'start' }}>
                <div>
                  <Typography className={indicacoesStyles.text}>
                    Telefone
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="body2"
                  >
                    {isEmpty(model.cliente?.informacoes) ? "Não informado" : phoneMask(model.cliente?.informacoes?.telefone)}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={7} md={3} className={indicacoesStyles.formartText}>
                <Typography className={indicacoesStyles.text}>
                  Contato
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  {model.contato}
                </Typography>
              </Grid>
              {model.cliente?.informacoes && (
                <Grid item xs={12} >
                  <div>
                    <Typography className={indicacoesStyles.text}>
                      Endereço
                    </Typography>
                    <Typography
                      color="textPrimary"
                      variant="body2"
                    >
                      {retornaEndereco}
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>
          </Box>

        ) : (
          <>
            <div className={`${classes.cardContent} ${indicacoesStyles.container}`}>
              <Box p={1} width="100%">
                <Grid container spacing={1}>
                  <Box width="100%">
                    <Box display={"flex"} justifyContent={"space-between"} alignItems="center" paddingX={1}>
                      <Typography>
                        <Box fontWeight={600} color={theme.palette.grey[600]}>
                          {toDateString(model.data)}
                        </Box>
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <Typography className={indicacoesStyles.statusText} style={{ backgroundColor: retornarCorStatus(model.status) }}>
                          {StatusGeralIndicacoMock.find(x => x.Key === model.status)?.Value}
                        </Typography>
                        <Tooltip title="Visualizar Dados da Indicação" arrow>
                          <Box display="flex" className={indicacoesStyles.btnAvancar}>
                            <AvancarIcon tipo="BUTTON" />
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={5} md={2}>
                    <Typography className={indicacoesStyles.text}>
                      CPF/CNPJ
                    </Typography>
                    <Typography className={indicacoesStyles.subText}>
                      {formatarCPFCNPJ(model.cliente?.cpfCnpj)}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} md={2} className={indicacoesStyles.formartText}>
                    <Typography className={indicacoesStyles.text}>
                      Razão Social
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: 700, fontSize: '13px' }}>
                      {model.cliente?.nomeComercial}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} md={2} style={{ display: 'flex', justifyContent: 'start' }}>
                    <div>
                      <Typography className={indicacoesStyles.text}>
                        Telefone
                      </Typography>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {isEmpty(model.cliente?.informacoes) ? "Não informado" : phoneMask(model.cliente?.informacoes?.telefone)}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={7} md={2} className={indicacoesStyles.formartText}>
                    <Typography className={indicacoesStyles.text}>
                      Contato
                    </Typography>
                    <Typography
                      color="textPrimary"
                      variant="body2"
                    >
                      {model.contato}
                    </Typography>
                  </Grid>
                  {model.cliente?.informacoes && (
                    <Grid item xs={5} md={4} >
                      <div>
                        <Typography className={indicacoesStyles.text}>
                          Endereço
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          {retornaEndereco}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </div>
          </>
        )}
      </DefaultCard >
    </>
  );
};
