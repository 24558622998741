import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useFormIndicacaoInteresseValidation } from './form-indicacao-interesse-validation';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IndicacaoInteresseFormModel } from 'model/app/forms/indicacao/indicacao-interesse-form-model';
import { useGetSistemas } from 'data/api/gestao/sistema';
import { useToastSaurus } from 'services/app';
import { SistemaListModel } from 'model/api/gestao/sistema/sistema-list-model';
import { AutocompleteRevendas } from 'views/components/controles/autocompletes/autocomplete-revendas/autocomplete-revendas';

export const FormIndicacaoInteresse = forwardRef<
  DefaultFormRefs<IndicacaoInteresseFormModel>,
  DefaultFormProps<IndicacaoInteresseFormModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<IndicacaoInteresseFormModel>,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { getSistemas } = useGetSistemas()
    const { showToast } = useToastSaurus()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sistemaList, setSistemaList] = useState<SistemaListModel[]>([])
    // const [sistemaSelecionado, setSistemaSelecionado] = useState<string>('')

    const { FormIndicacaoInteresseValidationYup } = useFormIndicacaoInteresseValidation()

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
      setValue,
      
    } = useForm<IndicacaoInteresseFormModel>({
      resolver: yupResolver(FormIndicacaoInteresseValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const getSistemasWrapper = useCallback(async () => {
      try {
        const res = await getSistemas(1, `pageSize=0`)
        if (res.erro) {
          throw res.erro
        }

        if (!res.resultado) {
          return
        }

        setSistemaList(res.resultado.data.list)

      } catch (err: any) {
        showToast('error', err.message)
      }
    }, [getSistemas, showToast])

    useEffect(() => {
      getSistemasWrapper()
    }, [getSistemasWrapper])

    const submitAlterarDadosConta = (values: IndicacaoInteresseFormModel) => {
      onSubmit(values);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: IndicacaoInteresseFormModel) => {
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    // const marks = [
    //   {
    //     value: 0,
    //     label: '0%',
    //   },
    //   {
    //     value: 50,
    //     label: '50%',
    //   },
    //   {
    //     value: 100,
    //     label: '100%',
    //   },
    // ];

    return (
      <>
        <Box my={3} width={"100%"} style={{
          paddingBottom: window.innerHeight <= 390 ? '50%' : '0%',
        }}>
          <div className={utilClasses.formContainer}>
            <form
              onSubmit={handleSubmit(submitAlterarDadosConta)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                  <Box width="100%">
                    <Card style={{
                      width: '100%'
                    }}>
                      <Box p={3} width="100%" textAlign={"center"}>
                        <Typography variant="h5">
                          Nível de Interesse
                        </Typography>
                        <Box marginTop={3}>
                          <Controller
                            control={control}
                            name="valorFaturanmentoEstimado"
                            render={({ field }) => (
                              <Slider
                                min={0}
                                max={100}
                                step={10}
                                value={getValues('nivelDeInteresse')}
                                valueLabelDisplay="auto"
                                aria-labelledby="discrete-slider-always"
                                marks={marks}
                                onChange={(ev, newValue) => setValue('nivelDeInteresse', Number(newValue))}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Typography variant="h5" color="primary" style={{ fontWeight: 600 }}>
                    Instrução para Contato
                  </Typography>
                  <Divider />
                </Grid> */}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="instrucao"
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Instrução para Atuação"
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={5}
                        placeholder='Digite informações para facilitar a atuação do cliente.'
                        error={Boolean(errors.instrucao && errors.instrucao.message)}
                        helperText={
                          touchedFields.instrucao || errors.instrucao
                            ? errors.instrucao?.message
                            : undefined}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name='responsavelId'
                    render={({ field }) => (
                      <AutocompleteRevendas
                        label='Vincular Responsavel (Opcional)'
                        value={getValues('responsavelId') || undefined}
                        fetchOnLoad
                        onChange={(e) => {
                          setValue('responsavelId', e.value.id)
                        }}
                        helperText={
                          errors.responsavelId
                            ? errors.responsavelId.message
                            : undefined
                        }
                        error={Boolean(errors.responsavelId && errors.responsavelId.message)}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <Box marginTop={2}>
                    <Typography variant="h5" color="primary" style={{ fontWeight: 600 }}>
                      Lista de Sistemas <span style={{ fontWeight: 500, color: theme.palette.grey[400], fontSize: 18 }}>(Opcional)</span>
                    </Typography>
                    <Divider />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {(carregando) && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                      <CircularLoading tipo="NORMAL" />
                    </div>
                  ) : null}
                  <Grid container spacing={2}>
                    {!carregando && sistemaList.map(x => {
                      return (
                        <Grid item xs={4} key={x.id}>
                          <CardIndicacaoSistema
                            model={x}
                            onClick={() => {
                              if (sistemaSelecionado === x.id) {
                                setSistemaSelecionado('')
                                setValue('sistemaId', '')
                                return
                              }
                              setValue('sistemaId', x.id)
                              setSistemaSelecionado(x.id)
                            }}
                            onCheck={() => { }}
                            selected={(sistemaSelecionado || getValues('sistemaId')) === x.id}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid> */}
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
