import { useCallback } from 'react';
import {
  ConfiguracaoIcon,
  LogoutIcon,
} from '../icons';
import { EnumMenu } from 'model';
import { IconsHelperProps } from './icons-helper-props';
import { PessoaIcon } from '../icons/pessoa-icon';

export const IconsHelper = (props: IconsHelperProps) => {
  const selectIcon = useCallback(() => {
    switch (props.codigoModulo) {
      case EnumMenu.MASTER_SAIR:
        return <LogoutIcon {...props} />;
      case EnumMenu.INDICACAO:
        return <PessoaIcon {...props} />;
      case EnumMenu.CONFIGURACOES:
        return <ConfiguracaoIcon {...props} />
      default:
        return <></>;
    }
  }, [props]);

  return <>{selectIcon()}</>;
};
