export class IndicacaoEmpresaFormModel {
  constructor(
    public fantasia: string = '',
    public faturamentoEstimado: number = 0,
    public cep: string = '',
    public logradouro: string = '',
    public numero: string = '',
    public bairro: string = '',
    public municipio: string = '',
    public codMunicipio: number = 0,
    public uf: string = '',
    public complemento: string = '',
  ) { }
}

