import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    chat: {
        "&.MuiOutlinedInput-root": {
            borderRadius: 6,
            // "& .MuiOutlinedInput-notchedOutline": {
            //     borderRight: 'none',
            //     borderLeft: 'none',
            //     borderBottom: 'none',
            //     borderTopWidth: '2px!important',
            // }
        },
        "& .MuiInputBase-input": {
            height: '2rem'
        }
    },
    btnEnviar: {
        "& svg": {
            padding: 0,
            margin: 0
        },
        height: '100%',
        width: '100%',
        padding: 0,
        marginLeft: theme.spacing(1)
    },
    loading: {
        position: 'absolute',
        top: '50px',
        left: '50%',
        transform: "translate(-50%, -50%)",
        '& .loader-container': {
            width: 60,
            height: 60,
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            background: "#FFF",
            boxShadow: '1px 1px 2px rgba(0,0,0,0.1)'
        },
        '& .loader-container .loader': {
            transform: "translate(-19%, 1%)",
        }
    },
    btnLoading: {
        "& svg": {
            margin: 0,
            fill: theme.palette.grey[600]
        },
        padding: 0,
        height: 50,
        width: 50
    },
    containerBalao: {
        maxWidth: 'calc(100% - 150px)',
        [theme.breakpoints.down("xs")]: {
            maxWidth: 'calc(100% - 100px)'
        },
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
}))