import { useCallback } from "react";
import { EnumMenu } from 'model';
import { ModulosMock } from 'data/mocks';

export const useGetMenu = () => {

  const getMenu = useCallback((isMovAberta: boolean, modulos?: EnumMenu[]) => {

    let modulos_filtered = ModulosMock
      //FILTRO MODULOS QUE PEDIRAM PARA ESCONDER
      .filter(x => !modulos?.includes(x.codigoPai))
      // .filter(x => { return validaModuloNasPermissoes(perfilAtual, x) })
      .sort((firstEl, secondEl) => firstEl.posicao - secondEl.posicao);

    //TIRO OS PAIS SEM FILHOS


    return modulos_filtered;
  }, []);

  return { getMenu };
};
