import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useFormIndicacaoDadosValidation } from './form-indicacao-dados-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,
} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IndicacaoDadosFormModel } from 'model/app/forms/indicacao/indicacao-dados-form-model';

export const FormIndicacaoDados = forwardRef<
  DefaultFormRefs<IndicacaoDadosFormModel>,
  DefaultFormProps<IndicacaoDadosFormModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<IndicacaoDadosFormModel>,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);

    const { FormIndicacaoDadosValidationYup } = useFormIndicacaoDadosValidation()

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset
    } = useForm<IndicacaoDadosFormModel>({
      resolver: yupResolver(FormIndicacaoDadosValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const submitEnviarDados = (values: IndicacaoDadosFormModel) => {
      onSubmit(values);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitEnviarDados)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: IndicacaoDadosFormModel) => {
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    return (
      <>
        <Box>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitEnviarDados)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Box marginTop={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="contato"
                      render={({ field }) => (
                        <TextFieldSaurus
                          label="Nome do Responsável da Empresa"
                          fullWidth
                          variant='outlined'
                          tipo='TEXTO'
                          error={Boolean(errors.contato && errors.contato.message)}
                          helperText={
                            touchedFields.contato || errors.contato
                              ? errors.contato?.message
                              : undefined}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="cpfCnpj"
                      render={({ field }) => (
                        <TextFieldSaurus
                          label="CPF / CNPJ do Estabelecimento"
                          fullWidth
                          variant='outlined'
                          tipo='CNPJ_CPF'
                          error={Boolean(errors.cpfCnpj && errors.cpfCnpj.message)}
                          helperText={
                            touchedFields.cpfCnpj || errors.cpfCnpj
                              ? errors.cpfCnpj?.message
                              : undefined}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="telefone"
                      render={({ field }) => (
                        <TextFieldSaurus
                          label="Telefone de Contato"
                          fullWidth
                          variant='outlined'
                          tipo='TELEFONE'
                          error={Boolean(errors.telefone && errors.telefone.message)}
                          helperText={
                            touchedFields.telefone || errors.telefone
                              ? errors.telefone?.message
                              : undefined}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="email"
                      render={({ field }) => (
                        <TextFieldSaurus
                          label="E-mail"
                          fullWidth
                          variant='outlined'
                          tipo='EMAIL'
                          allowSubmit
                          error={Boolean(errors.email && errors.email.message)}
                          helperText={
                            touchedFields.email || errors.email
                              ? errors.email?.message
                              : undefined}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
