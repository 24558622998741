import { Box, Grid, Typography } from '@material-ui/core';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect, useMemo } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useCadastros } from 'services/app';
import {
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { PagamentoFormModel } from 'model/app/forms/pagamento/pagamento-form-model';
import { useStepperStyles } from 'views/components/stepper/stepper-styles';
import { Stepper } from 'views/components/stepper';
import { useIndicacaoCadastro } from './components/use-indicacao-cadastro';
import classNames from 'classnames';
import { CircularLoading } from 'views/components/utils';

export const IndicacaoCadastro = () => {

  const stepperClasses = useStepperStyles();
  const { carregando, formArray, formStepper } = useIndicacaoCadastro()

  const { fecharCadastroIndicacao } =
    useCadastros();
  const cadNovaPagamentoFormRef =
    useRef<DefaultFormRefs<PagamentoFormModel>>(null);
  const classes = useModalStyles();

  useEffect(() => {
    cadNovaPagamentoFormRef.current?.fillForm(
      new PagamentoFormModel(),
    );
  }, []);

  const onCloseClick = useCallback(() => {
    fecharCadastroIndicacao()
  }, [fecharCadastroIndicacao]);

  const getButtons = useMemo(() => {
    return (
      <div className={stepperClasses.stepButtons}>
        <Box mt={3}>
          <Grid spacing={2} container>
            {formArray[formStepper.currentStep].previousButton && (
              <Grid item xs={12} sm={5}>
                {formArray[formStepper.currentStep].previousButton}
              </Grid>
            )}

            {formArray[formStepper.currentStep].nextButton && (
              <Grid
                item
                xs={12}
                sm={formArray[formStepper.currentStep].previousButton ? 7 : 12}
              >
                {formArray[formStepper.currentStep].nextButton}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    );
  }, [stepperClasses.stepButtons, formArray, formStepper.currentStep]);

  return (
    <div className={classes.root} style={{ overflowY: 'hidden' }}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Nova Indicação'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div className={stepperClasses.stepper}>
          <Stepper
            linear
            steps={formArray}
            stepAtual={formStepper.currentStep}
          />
        </div>
        <div className={stepperClasses.stepFix}>
          <div className={classNames(stepperClasses.step)}>
            <div className={stepperClasses.stepTitleContent}>
              <Typography
                align="center"
                className={stepperClasses.stepTitle}
                variant="h4"
                color="primary"
              >
                {formArray[formStepper.currentStep].formTitleIcon}
                {formArray[formStepper.currentStep].formTitle}
              </Typography>
              <Box>
                <Typography align="center" variant="body1">
                  {formArray[formStepper.currentStep].formDesc}
                </Typography>
              </Box>
            </div>
            <div className={stepperClasses.stepForm}>
              {formArray[formStepper.currentStep].form}
            </div>
          </div>
        </div>
        {getButtons}
      </div>
    </div >
  );
};
