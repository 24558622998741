import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CadastrosContextValue } from './cadastro-context-value';
import { IndicacaoModal } from 'views/components/modals/indicacao/indicacao-modal';
import { isEmpty } from 'lodash';

const CadastrosContext = React.createContext<CadastrosContextValue>({

  abrirCadastroIndicacao: (
    id: string,
    callbackUrl: string,
    trocarUrl: boolean,
  ) => {
    return new Promise<void>(() => true);
  },
  fecharCadastroIndicacao: (urlRetorno?: string) => {
    return new Promise<void>(() => true);
  },
  cadastroIndicacaoState: {
    id: '',
    aberto: false,
    ultimaUrl: '',
  },
});



export interface CadastrosProviderProps {
  children: React.ReactNode;
}

export const useCadastros = () => {
  return React.useContext(CadastrosContext);
};

export const CadastrosProvider = ({ children }: CadastrosProviderProps) => {

  const novoCadastroRoute = 'adicionar';
  const indicacao = `/minhas-indicacoes/`;


  const { push, location } = useHistory();

  const [cadastroIndicacaoState, setCadastroIndicacaoState] = useState({
    id: '',
    aberto: false,
    ultimaUrl: '',
  });
  useEffect(() => {
    if (location.pathname.indexOf(indicacao) === 0) {
      const posrota = location.pathname.replace(indicacao, '');
      if (posrota.length > 0) {
        setCadastroIndicacaoState({
          id: posrota.replace(novoCadastroRoute, ''),
          aberto: true,
          ultimaUrl: indicacao,
        });
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //----------------------------------------------------------------------------------------------------------------Instituição
  const abrirCadastroIndicacao = React.useCallback(
    async (
      id: string,
      callbackUrl: string,
      trocarUrl: boolean,
    ): Promise<void> => {
      let url = callbackUrl.length === 0 ? indicacao : callbackUrl;
      if (cadastroIndicacaoState.aberto) {
        url = cadastroIndicacaoState.ultimaUrl;
      }
      setCadastroIndicacaoState({
        id: id,
        aberto: true,
        ultimaUrl: url,
      });
      if (trocarUrl) {
        push(indicacao + (id?.length === 0 ? novoCadastroRoute : id));
      }
    },
    //eslint-disable-next-line
    [location.pathname, indicacao],
  );

  const fecharCadastroIndicacao = React.useCallback(
    async (urlRetorno?: string): Promise<void> => {
      const uUrl = cadastroIndicacaoState.ultimaUrl;
      setCadastroIndicacaoState({
        id: '',
        aberto: false,
        ultimaUrl: '',
      }); if (!isEmpty(urlRetorno)) {
        push(urlRetorno || '');
        return;
      }
      if (!isEmpty(uUrl)) {
        push(uUrl);
        return;
      }
    },
    //eslint-disable-next-line
    [cadastroIndicacaoState.ultimaUrl, push],
  );


  return (
    <CadastrosContext.Provider
      value={{
        abrirCadastroIndicacao,
        fecharCadastroIndicacao,
        cadastroIndicacaoState,
      }}
    >
      {children}
      <IndicacaoModal
        openned={cadastroIndicacaoState.aberto}
        id={cadastroIndicacaoState.id}
      />
    </CadastrosContext.Provider>
  );
};
