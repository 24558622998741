
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormIndicacaoInteresseValidation = () => {


  const FormIndicacaoInteresseValidationYup = useMemo(() => {
    return Yup.object().shape({
      instrucao: Yup.string().required('O campo instrução é obrigatório.'),
    });
  }, [])

  return {
    FormIndicacaoInteresseValidationYup,
  }
}

