import { makeStyles } from "@material-ui/core";

export const useModalStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: "1",
  },
  content: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    background: '#FBFBFB'
  },
  contentAbas: {
    // background: theme.palette.common.white,
    // background: theme.palette.grey[100],
    paddingTop: theme.spacing(1),
    boxShadow: '0px 5px 5px rgba(0,0,0,0.05) inset',
    marginLeft: theme.spacing(2) * -1,
    marginRight: theme.spacing(2) * -1,
  },
  contentForms: {
    flex: "1 1 auto",
    padding: theme.spacing(2),
    height: "0px",
    overflowX: "hidden",
    overflowY: "auto",
    "& * > form": {
      visibility: "visible",
    },
    "&  .tabs-class": {
      visibility: "visible",
    },
  },
  contentFormsLoading: {
    "& * > form": {
      visibility: "hidden",
    },

    "&  .tabs-class": {
      visibility: "hidden",
    },
  },
  acoes: {
    flex: "0 0 auto",
    padding: theme.spacing(2),
  },
}));
