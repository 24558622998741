import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid } from "@material-ui/core";
import { PessoaSenhaModel } from "model/api/gestao/pessoa/pessoa-senha-model";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { picker } from "utils/picker";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { CircularLoading } from "views/components/utils";
import { makeUtilClasses, useThemeQueries } from "views/theme";
import { DefaultFormProps, DefaultFormRefs } from "../../utils";
import { useFormPessoaSenhaValidation } from "./form-editar-pessoa-senha-validation";

interface FormEditarPessoaProps extends DefaultFormProps<PessoaSenhaModel> {

}

export const FormEditarPessoaSenha = forwardRef<DefaultFormRefs<PessoaSenhaModel>, FormEditarPessoaProps>(({
    loading,
    onSubmit,
    ...props
}: FormEditarPessoaProps, ref) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormPessoaSenhaValidationYup } = useFormPessoaSenhaValidation()
    const [modelForm, setModelForm] = useState<PessoaSenhaModel>(new PessoaSenhaModel())

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
    } = useForm<PessoaSenhaModel>({
        defaultValues: { ...modelForm },
        resolver: yupResolver(FormPessoaSenhaValidationYup),
        criteriaMode: 'all',
        mode: 'onBlur' && 'onChange',
    });

    const carregando = loading

    const submitEnviar = (values: PessoaSenhaModel) => {
        const senha = picker<PessoaSenhaModel>(values, modelForm)
        onSubmit(senha, modelForm);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(submitEnviar)();
        },
        resetForm: () => {
            reset();
            if (!isMobile) refInputNome.current?.focus();
        },
        fillForm: (model: PessoaSenhaModel) => {
            setModelForm({ ...model })
            reset({ ...model });
            if (!isMobile) refInputNome.current?.focus();
        },
    }));

    return (
        <>
            <Box my={2}>
                <div className={utilClasses.formContainer}>
                    {carregando && props.showLoading && (
                        <div className={utilClasses.controlLoading}>
                            <CircularLoading tipo="FULLSIZED" />
                        </div>
                    )}

                    <form
                        onSubmit={handleSubmit(submitEnviar)}
                        className={loading ? utilClasses.controlLoading : ''}
                    >
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                                <Controller
                                    name="novaSenha"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            disabled={loading}
                                            allowSubmit={false}
                                            tipo="PASSWORD"
                                            label="Nova Senha"
                                            showPasswordVisibilityButton
                                            fullWidth
                                            autoComplete={'off'}
                                            helperText={
                                                touchedFields.novaSenha && errors.novaSenha
                                                    ? errors.novaSenha.message
                                                    : undefined
                                            }
                                            error={Boolean(errors.novaSenha && errors.novaSenha.message)}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="repetirSenha"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            disabled={loading}
                                            allowSubmit={false}
                                            tipo="PASSWORD"
                                            label="Confirmar Senha"
                                            showPasswordVisibilityButton
                                            fullWidth
                                            autoComplete={'off'}
                                            helperText={
                                                touchedFields.repetirSenha && errors.repetirSenha
                                                    ? errors.repetirSenha.message
                                                    : undefined
                                            }
                                            error={Boolean(errors.repetirSenha && errors.repetirSenha.message)}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </div>
            </Box>
        </>
    );
},
); 