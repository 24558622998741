import { useEffect, useRef, useState } from "react";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { Button, Grid } from "@material-ui/core";
import { useThemeQueries } from "views/theme";
import { usePutPessoaClienteIdeal } from "data/api/gestao/cliente-ideal/put-pessoa-cliente-ideal";
import { CircularLoading } from "views/components/utils";
import { useToastSaurus } from "services/app";
import { AvancarIcon, OkIcon, VoltarIcon } from "views/components/icons";
import { PessoaClienteGetModel } from "model/api/gestao/pessoa-cliente-ideal/pessoa-cliente-model";
import { FormPerguntaRefs, FormPerguntas } from "views/components/form/master/indicacao/indicacao-cadastro/form-perguntas/form-perguntas";
import { PessoaClienteIdealPutModel } from "model/api/gestao/pessoa-cliente-ideal/pessoa-cliente-ideal-put-model";
import { CloseIcon } from "views/components/icons/close-icon";

interface DialogPerguntasProps {
    aberto: boolean;
    pendentes?: number
    close: () => void;
    pessoaId: string;
    model: PessoaClienteGetModel;
}

export const DialogPerguntas = ({
    aberto,
    close,
    pessoaId,
    model,
    pendentes
}: DialogPerguntasProps) => {

    const formRef = useRef<FormPerguntaRefs>(null)
    const { isMobile } = useThemeQueries();
    const { showToast } = useToastSaurus();
    const [botoes, setBotoes] = useState({
        voltar: (
            <>
                <CloseIcon tipo='BUTTON' />
                Fechar
            </>
        ),
        avancar: (
            <>
                <AvancarIcon tipo='BUTTON_PRIMARY' />
                Avançar
            </>
        )
    })

    const { putPessoaClienteIdeal, carregando } = usePutPessoaClienteIdeal();

    const handleSubmit = async (model: PessoaClienteIdealPutModel[]) => {
        try {
            model = model.map(resp => {
                return {
                    ...resp,
                    pessoaId
                }
            })
            const modelToSend: PessoaClienteIdealPutModel[] = [
                ...model,
                ...respsAux.current.filter((item) => {
                    const findInModel = model.find((itemModel) => {
                        return itemModel.perguntaId === item.perguntaId
                    })
                    if (findInModel) {
                        return false
                    } else {
                        return true
                    }
                })
            ]
            const res = await putPessoaClienteIdeal(pessoaId, modelToSend);

            if (res.erro) throw res.erro;

            showToast('success', 'Avaliação de Cliente Atualizada.');
            close();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }

    const clickNext = () => {
        if (formRef.current?.isUltimaPergunta()) {
            formRef.current.submitForm();
            return
        }
        formRef.current?.avancarPergunta();
    }

    const clickBack = () => {
        if (formRef.current?.isPrimeiraPergunta()) {
            close();
            return
        }
        formRef.current?.voltarPergunta();
    }
    const respsAux = useRef<PessoaClienteIdealPutModel[]>([])

    useEffect(() => {
        if (aberto) {
            const resps: PessoaClienteIdealPutModel[] = model.respostas.map(resposta => new PessoaClienteIdealPutModel(pessoaId, resposta.perguntaId, resposta.respostaId));
            respsAux.current = resps
            setTimeout(() => {
                formRef.current?.fillForm(resps || [])
            }, 25)
        }
    }, [aberto, model.respostas, pessoaId])

    const alterarBotoes = (perguntasLength: number, index: number) => {
        if (perguntasLength === 1) {
            setBotoes({
                voltar: (
                    <>
                        <CloseIcon tipo='BUTTON' />
                        Fechar
                    </>
                ),
                avancar: (
                    <>
                        <OkIcon tipo='BUTTON_PRIMARY' />
                        Finalizar
                    </>
                )
            })
            return
        }
        if (perguntasLength - 1 === index) {
            setBotoes(() => ({
                voltar: (
                    <>
                        <VoltarIcon tipo='BUTTON' />
                        Voltar
                    </>
                ),
                avancar: (
                    <>
                        <OkIcon tipo='BUTTON_PRIMARY' />
                        Finalizar
                    </>
                ),
            }))
            return
        }
        if (index === 0) {
            setBotoes(prev => ({
                avancar: (
                    <>
                        <AvancarIcon tipo='BUTTON_PRIMARY' />
                        Avançar
                    </>
                ),
                voltar: (
                    <>
                        <CloseIcon tipo='BUTTON' />
                        Fechar
                    </>
                ),
            }))
            return
        }

        setBotoes({
            voltar: (
                <>
                    <VoltarIcon tipo='BUTTON' />
                    Voltar
                </>
            ),
            avancar: (
                <>
                    <AvancarIcon tipo='BUTTON_PRIMARY' />
                    Avançar
                </>
            )
        })

    }

    return (
        <DialogSaurus
            aberto={aberto}
            titulo="Avaliação - Cliente Ideal"
            fullScreen={isMobile}
            tamanho='sm'
            isButtonTitleClose
            handleClickClose={close}
        >
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Grid container spacing={2} justifyContent="space-between" direction="column" style={{
                minHeight: '100%'
            }}>
                <Grid item>
                    <FormPerguntas
                        pendentes={pendentes}
                        loading={carregando}
                        perguntas={[]}
                        ref={formRef}
                        showLoading={false}
                        onSubmit={handleSubmit}
                        close={close}
                        alterarBotoes={alterarBotoes}
                    />
                </Grid>
                <Grid item>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={'auto'}>
                            <Button variant='outlined' color='primary' onClick={clickBack}>
                                {botoes.voltar}
                            </Button>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Button variant='contained' color='primary' onClick={clickNext}>
                                {botoes.avancar}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogSaurus>
    )
}