import { useTheme } from "@material-ui/core"

export const BackgroundHeaderLeft = () => {

    const theme = useTheme()

    return (
        <>
            <svg width="190" height="241" viewBox="0 0 190 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M58.1323 13.9516C102.988 39.3859 130.41 72.3831 140.397 112.943C150.589 153.142 141.635 198.02 113.534 247.579L-18.7654 480.904L-106.669 431.061L24.0918 200.449C39.2703 173.68 43.6002 149.604 37.0814 128.22C31.1296 106.679 16.7587 89.4478 -6.03111 76.5256C-30.9914 62.3727 -54.9896 58.3262 -78.0257 64.3862C-100.857 70.0845 -120.477 87.4033 -136.886 116.343L-264.57 341.528L-352.474 291.685L-221.713 61.0737C-206.534 34.3047 -201.662 10.5359 -207.095 -10.2328C-212.324 -31.3632 -226.152 -48.2869 -248.58 -61.0041C-273.179 -74.9519 -296.996 -78.8958 -320.032 -72.8358C-342.863 -67.1375 -362.483 -49.8186 -378.892 -20.8792L-506.576 204.306L-594.48 154.463L-379.109 -225.367L-293.919 -177.063L-320.378 -130.398C-299.162 -140.836 -276.404 -145.142 -252.104 -143.314C-227.598 -141.848 -202.866 -134.039 -177.905 -119.886C-151.86 -105.118 -131.839 -87.3119 -117.843 -66.4686C-103.642 -45.9871 -96.4238 -23.7286 -96.189 0.306949C-72.9105 -10.3961 -48.1141 -14.5019 -21.8 -12.0106C5.08092 -9.67585 31.725 -1.02179 58.1323 13.9516Z" fill="url(#paint0_radial_2833_8073)" fillOpacity="0.5" />
                <defs>
                    <radialGradient id="paint0_radial_2833_8073" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(36.5 48.5) rotate(81.3557) scale(299.401 216.922)">
                        <stop offset="0.0248719" stopColor={theme.palette.primary.main} stopOpacity="0.14" />
                        <stop offset="0.690172" stopColor={theme.palette.primary.main} stopOpacity="0" />
                    </radialGradient>
                </defs>
            </svg>
        </>
    )
}
