import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  listContainer: {
    '& .card': {
      margin: theme.spacing(1),
    },
  },
  list: {
    flex: '1 1 100%',
    overflowX: 'hidden',
    overflow: 'auto',
    position: 'relative',
  },
}));
