import { Box, Button, Grid, Slide, Typography } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { useStyles } from './form-login-styles';
import { useFormLoginValidation } from './form-login-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { LoginFormModel } from 'model/app';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChatSuporteSaurus } from 'utils/chat-suporte-saurus';

interface Props extends DefaultFormProps<LoginFormModel> {
  fase: 1 | 2;
  setFase: (valor: 1 | 2) => void;
  mostrarFase: 1 | 2;
}

export const FormLogin = React.forwardRef<
  DefaultFormRefs<LoginFormModel>,
  Props
>(({ fase, setFase, mostrarFase, ...props }: Props, refs) => {
  const txtEmail = useRef<HTMLInputElement>();
  const txtSenha = useRef<HTMLInputElement>();
  const { FormLoginYupValidation } = useFormLoginValidation(fase);
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { abrirChat } = useChatSuporteSaurus();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues
  } = useForm<LoginFormModel>({
    resolver: yupResolver(FormLoginYupValidation),
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });


  const onSubmit = (form: LoginFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({ cpfCnpj: getValues('cpfCnpj'), senha: '' });
      if (!isMobile) txtEmail?.current?.focus();
    },
    fillForm: (model: LoginFormModel) => {
      reset({ ...model });
      if (!isMobile) txtEmail?.current?.focus();
    },
  }));

  useEffect(() => {
    if (fase === 2) {
      if (!isMobile) txtSenha?.current?.focus();
    }
  }, [fase, isMobile])

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          {mostrarFase === 1 && (
            <Slide in={fase === 1} direction={fase === 1 ? 'left' : 'right'}>
              <Grid container style={{ maxWidth: '100%' }} direction='column' justifyContent='center'>
                <Grid item xs={12} style={{ marginBottom: 30 }}>
                  <Controller
                    name="cpfCnpj"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="CNPJ_CPF_LOGIN"
                        disabled={props.loading}
                        fullWidth={true}
                        showStartAdornment={true}
                        inputRef={txtEmail}
                        allowSubmit
                        autoComplete={'off'}
                        label="CPF / CNPJ"
                        placeholder="Digite o Documento"
                        error={Boolean(errors.cpfCnpj && errors.cpfCnpj.message)}
                        helperText={
                          touchedFields.cpfCnpj || errors.cpfCnpj
                            ? errors.cpfCnpj?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Slide>
          )}
          {mostrarFase === 2 && (
            <Slide in={fase === 2} direction={fase === 2 ? 'left' : 'right'} >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    mb={Boolean(touchedFields.senha && errors.senha) ? 0 : 1.5}
                  >
                    <Controller
                      name="senha"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          tipo="PASSWORD"
                          allowSubmit
                          disabled={props.loading}
                          inputRef={txtSenha}
                          showStartAdornment={true}
                          fullWidth={true}
                          autoComplete={'off'}
                          label="Senha"
                          placeholder="Digite sua Senha"
                          error={Boolean(errors.senha && errors.senha.message)}
                          helperText={
                            touchedFields.senha || errors.senha
                              ? errors.senha?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <Typography
                    color='primary'
                    variant='body2'
                    onClick={() => {
                     abrirChat();
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    Esqueceu sua Senha?
                  </Typography>
                </Grid>
              </Grid>
            </Slide>
          )}
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
