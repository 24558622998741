import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { InteracoesPostModel } from 'model/api/gestao/interacoes/interacoes-post-model';

export function usePostInteracoes() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postInteracoes = useCallback(
        (id: string, interacao: InteracoesPostModel) =>
            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/Indicacao/${id}/interacoes`,
                method: 'POST',
                data: interacao,
                enviarTokenUsuario: true,
                timeout: 100000
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postInteracoes,
    };
}
