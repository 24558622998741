import { useCallback } from "react";
import { stringNumeros } from "utils/string-numeros";

export function useChatSuporteSaurus() {
    let foneComercial = '11 95402-3195'

    const abrirChat = useCallback((): void => {
        const message = `Olá, estou usando o APP de Indicação da Saurus e acabei perdendo minha senha. Você pode redefini-la?`
        window.open(`https://api.whatsapp.com/send?phone=55${stringNumeros(foneComercial ?? '')}&text=${message}`);
    }, [foneComercial]);

    return {
        abrirChat
    };
}