
import { useMemo } from 'react';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const useFormPessoaEditValidation = () => {
  const FormPessoaEditValidationYup = useMemo(() => {
    return Yup.object().shape<AnyObject>({
      nomeComercial: Yup.string().required('Nome é obrigatório'),
    });
  }, [])

  return {
    FormPessoaEditValidationYup,
  }
}
