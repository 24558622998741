import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardImg: {
        display: "flex",
        objectFit: "cover",
        zIndex: 1,
        position: 'relative',
        background: theme.palette.common.white,
        width: "100px",
        height: "100px",
        borderRadius: theme.shape.borderRadius,
        transition: "0.2s ease-in-out transform",
        boxShadow: '0px 1.9698734283447266px 3.939746856689453px 0px #00000026'
    },
}))