import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { ConfiguracoesEditar } from './components/configuracoes-editar/configuracoes-editar';
import { ConfiguracoesHeader } from './components/configuracoes-header/configuracoes-header';

export const ConfiguracoesPage = () => {
    const classes = useDefaultCadastroStyles();

    const configuracoesEditar = useMemo(() => (
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Grid container spacing={1} style={{
            maxWidth: 1400,
            padding: 16
          }}>
            <ConfiguracoesEditar />
          </Grid>
        </div>
      ), [])
    
    return (
        <Grid className={classes.root}>
            <Grid className={classes.header}>
                <ConfiguracoesHeader titulo='Configurações'/>
            </Grid>
            <Grid className={classes.list}>
                {configuracoesEditar}
            </Grid>
        </Grid>
    );
};

export default ConfiguracoesPage;
