import { useState } from 'react';
import { MinhasIndicacoesHeader } from './components/minhas-indicacoes-header/minhas-indicacoes-header';
import { MinhasIndicacoesList } from './components/minhas-indicacoes-list/minhas-indicacoes-list';
import { Grid } from '@material-ui/core';
import { MinhasIndicacoesListSearchProps } from './components/minhas-indicacoes-list/minhas-indicacoes-list-search-props';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { ButtonFab } from 'views/components/controles';
import { useCadastros } from 'services/app';
import { NovoIcon } from 'views/components/icons';

export const MinhasIndicacoesPage = () => {
  const classes = useDefaultCadastroStyles();

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<MinhasIndicacoesListSearchProps>({
    termo: '',
  });
  const { abrirCadastroIndicacao } = useCadastros()

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <MinhasIndicacoesHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </Grid>
      <Grid className={classes.list}>
        <MinhasIndicacoesList searchProps={searchProps} />
      </Grid>
      <ButtonFab
        tooltip="Nova Indicação"
        icon={<NovoIcon tipo="BUTTON_FAB" />}
        onClick={() => abrirCadastroIndicacao('', '', true)}
      />
    </Grid>
  );
};

export default MinhasIndicacoesPage;
