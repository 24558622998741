import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, Card, Typography, Slider, Divider } from '@material-ui/core';
import { useFormIndicacaoEdicaoValidation } from './form-indicacao-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { OkIcon } from 'views/components/icons';
import { IndicacaoAvaliacaoPutModel } from 'model/api/gestao/indicacao/indicacao-avaliacao-put-model';
import { useIndicacaoEdicaoStyles } from './form-indicacao-edicao-styles';

interface Props extends DefaultFormProps<IndicacaoAvaliacaoPutModel> {
  hasValor: boolean
}

export const FormIndicacaoEdicao = forwardRef<
  DefaultFormRefs<IndicacaoAvaliacaoPutModel>,
  Props
>(
  (
    { loading, onSubmit, ...props }: Props,
    ref,
  ) => {
    const { isMobile, theme } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const [model, setModel] = useState<IndicacaoAvaliacaoPutModel>(new IndicacaoAvaliacaoPutModel())
    const classes = useIndicacaoEdicaoStyles()

    const { FormIndicacaoEdicaoValidationYup } = useFormIndicacaoEdicaoValidation()


    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
      setValue
    } = useForm<IndicacaoAvaliacaoPutModel>({
      defaultValues: { ...model },
      resolver: yupResolver(FormIndicacaoEdicaoValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const submit = (values: IndicacaoAvaliacaoPutModel) => {

      onSubmit(values, model);
    };

    useEffect(() => {
      setModel(props.model ?? new IndicacaoAvaliacaoPutModel())
      reset({ ...props.model ?? new IndicacaoAvaliacaoPutModel() })
    }, [props.model, reset])

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submit)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (values: IndicacaoAvaliacaoPutModel) => {
        setModel(values)
        reset({ ...values })
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    const marks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 10,
        label: '10',
      },
    ];


    return (
      <>
        <Grid item xs={12}>
          <Box mb={3}>
            <Card style={{ position: 'relative' }}>
              {loading && <CircularLoading tipo="FULLSIZED" />}
              <Box p={2}>
                <form
                  onSubmit={handleSubmit(submit)}
                  className={loading ? utilClasses.controlLoading : ''}
                >
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <Box width="100%">
                        <Typography variant="h6" color="primary">
                          <Box fontWeight={600}>
                            Avaliação
                          </Box>
                        </Typography>
                        <Divider />
                        <Box marginTop={5} style={{
                          padding: '0 8px'
                        }}>
                          <Controller
                            control={control}
                            name="avaliacaoIndicador"
                            render={({ field }) => (
                              <Slider
                                min={0}
                                max={10}
                                className={classes.slider}
                                disabled={loading || props.hasValor}
                                value={getValues('avaliacaoIndicador')}
                                aria-labelledby="discrete-slider-always"
                                valueLabelDisplay={props.hasValor ? "on" : "auto"}
                                marks={marks}
                                onChange={(ev, newValue) => setValue('avaliacaoIndicador', Number(newValue))}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} style={{ wordBreak: 'break-word' }}>
                      <Controller
                        control={control}
                        name="motivoAvaliacaoIndicador"
                        render={({ field }) => (
                          <>
                            {props.hasValor ? (
                              <>
                                <Typography variant="body2">
                                  <Box fontWeight={400} color={theme.palette.grey[400]}>
                                    Motivo de Nota
                                  </Box>
                                </Typography>
                                <Typography variant="body1">
                                  <Box fontWeight={500}>
                                    {model.motivoAvaliacaoIndicador}
                                  </Box>
                                </Typography>
                              </>
                            ) : (
                              <TextFieldSaurus
                                multiline
                                disabled={loading}
                                fullWidth
                                minRows={3}
                                maxRows={6}
                                label="Motivo da Nota"
                                placeholder='Descreva a sua experiência de atendimento e o motivo'
                                error={Boolean(errors.motivoAvaliacaoIndicador && errors.motivoAvaliacaoIndicador.message)}
                                allowSubmit
                                helperText={
                                  touchedFields.motivoAvaliacaoIndicador || errors.motivoAvaliacaoIndicador
                                    ? errors.motivoAvaliacaoIndicador?.message
                                    : undefined}
                                {...field}
                              />
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    {!props.hasValor && (
                      <Grid item xs={12} container justifyContent='flex-end'>
                        <Button variant="contained" color="primary" type="submit">
                          <OkIcon tipo="BUTTON_PRIMARY" />
                          Confirmar
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </form>
              </Box>
            </Card>
          </Box>
        </Grid >
      </>
    );
  },
);
