export enum EnumMenu {


  INDICACAO_GROUP = 2000,
  INDICACAO = 2001,
  CONFIGURACOES_GROUP = 2002,
  CONFIGURACOES = 2003,

  //SAIR
  MASTER_SAIR = 99999,
}
