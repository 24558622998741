import { EnumTipoCobrancaRevenda } from "model/enums/enum-tipo-cobranca-revenda";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { guidEmpty } from "utils/guid-empty";

export class PessoaPostModel {
    constructor(
        public id: string = guidEmpty(),
        public tipo: EnumTipoPessoas = EnumTipoPessoas.Cliente,
        public nomeComercial: string = '',
        public responsavel: string = '',
        public cpfCnpj: string = '',
        public status: number = 0,
        public diasLimiteAtrasoClientes: number = 0,
        public valorMinimoFatura: number = 0,
        public melhorDia: number | null = null,
        public login: string | null = null,
        public senha: string | null = null,
        public bloquearContratos: boolean = false,
        public mensagemAosContratos: string = '',
        public urlImagem: string = '',
        public revendaId: string | null = null,
        public telefoneSuporte: string | null = null,
        public tipoCobrancaRevenda: EnumTipoCobrancaRevenda | null = null,
        public chaveAtivacao: string = ''
    ) { }
}