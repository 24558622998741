import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, Divider, Typography } from '@material-ui/core';
import { useFormIndicacaoEmpresaValidation } from './form-indicacao-empresa-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { IndicacaoEmpresaFormModel } from 'model/app/forms/indicacao/indicacao-empresa-form-model';
import { useConsultaCEP } from 'data/api/wsmaster';
import { useToastSaurus } from 'services/app';
import { UFMock } from 'data/mocks';
import { isEmpty } from 'lodash';
import { picker } from 'utils/picker';

export interface FormIndicacaoDadosPreCadastroProps
  extends DefaultFormProps<IndicacaoEmpresaFormModel> {
  setCarregandoExterno(carregando: boolean): void;
}

export const FormIndicacaoEmpresa = forwardRef<
  DefaultFormRefs<IndicacaoEmpresaFormModel>,
  FormIndicacaoDadosPreCadastroProps
>(
  (
    { loading, onSubmit, ...props }: FormIndicacaoDadosPreCadastroProps,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const { showToast } = useToastSaurus()
    const utilClasses = makeUtilClasses();
    const { consultaCEP, carregando: carregandoCep } = useConsultaCEP();
    const txtNro = useRef<HTMLInputElement>()
    const txtLogradouro = useRef<HTMLInputElement>()
    const txtRazaoSocial = useRef<HTMLInputElement>()

    const carregando = loading || carregandoCep

    const { FormIndicacaoEmpresaValidationYup } = useFormIndicacaoEmpresaValidation()

    const [uf, setUF] = useState<string>('')

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      setValue,
    } = useForm<IndicacaoEmpresaFormModel>({
      resolver: yupResolver(FormIndicacaoEmpresaValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const submitAlterarDadosConta = (values: IndicacaoEmpresaFormModel) => {
      const dadosEmpresa = picker<IndicacaoEmpresaFormModel>(values, new IndicacaoEmpresaFormModel())
      onSubmit(dadosEmpresa);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) txtRazaoSocial.current?.focus();
      },
      fillForm: (model: IndicacaoEmpresaFormModel) => {
        reset({ ...model });
        if (!isMobile) txtRazaoSocial.current?.focus();
      },
    }));

    return (
      <>
        <Box mt={3} position={"relative"}>
          <div className={utilClasses.formContainer}>
            {carregando && props.showLoading ? (
              <CircularLoading tipo="FULLSIZED" />
            ) : null}

            <form
              onSubmit={handleSubmit(submitAlterarDadosConta)}
              className={carregando ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name='fantasia'
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Razão Social"
                        fullWidth
                        disabled={carregando}
                        error={Boolean(errors.fantasia && errors.fantasia.message)}
                        helperText={
                          touchedFields.fantasia || errors.fantasia
                            ? errors.fantasia?.message
                            : undefined}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="faturamentoEstimado"
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="VOP Mensal Estimado"
                        tipo='DECIMAL'
                        manterMascara
                        formatarMilhares
                        startAdornmentButton={
                          <Typography
                            variant='body2'
                            color='textPrimary'
                            style={{
                              marginRight: '8px'
                            }}
                          >
                            R$
                          </Typography>
                        }
                        error={Boolean(errors.faturamentoEstimado && errors.faturamentoEstimado.message)}
                        helperText={
                          touchedFields.faturamentoEstimado || errors.faturamentoEstimado
                            ? errors.faturamentoEstimado?.message
                            : undefined}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name='cep'
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="CEP"
                        fullWidth
                        tipo="CEP"
                        searchable
                        disabled={carregando}
                        error={Boolean(errors.cep && errors.cep.message)}
                        helperText={
                          touchedFields.cep || errors.cep
                            ? errors.cep?.message
                            : undefined}
                        {...field}
                        onSearch={async (value: string) => {
                          try {
                            let res = await consultaCEP(value);
                            setValue('cep', res.CEP);
                            setValue('bairro', res.Bairro);
                            setValue('logradouro', res.Logradouro);
                            setValue('uf', res.UF);
                            setValue('municipio', res.Municipio);
                            setValue('codMunicipio', Number(res.CMun));
                            setUF(res.UF)
                            if (!isMobile) txtNro.current?.focus();
                          } catch (e: any) {
                            showToast('error', e.message);
                            if (!isMobile) txtLogradouro.current?.focus();
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name='logradouro'
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Endereço"
                        fullWidth
                        disabled={carregando}
                        inputRef={txtLogradouro}
                        error={Boolean(errors.logradouro && errors.logradouro.message)}
                        helperText={
                          touchedFields.logradouro || errors.logradouro
                            ? errors.logradouro?.message
                            : undefined}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name='numero'
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Número"
                        fullWidth
                        disabled={carregando}
                        inputRef={txtNro}
                        error={Boolean(errors.numero && errors.numero.message)}
                        helperText={
                          touchedFields.numero || errors.numero
                            ? errors.numero?.message
                            : undefined}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name='bairro'
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Bairro"
                        fullWidth
                        disabled={carregando}
                        error={Boolean(errors.bairro && errors.bairro.message)}
                        helperText={
                          touchedFields.bairro || errors.bairro
                            ? errors.bairro?.message
                            : undefined}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name='municipio'
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Município"
                        fullWidth
                        disabled={carregando}
                        error={Boolean(errors.municipio && errors.municipio.message)}
                        helperText={
                          touchedFields.municipio || errors.municipio
                            ? errors.municipio?.message
                            : undefined}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name='codMunicipio'
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Cód Município"
                        fullWidth
                        disabled={carregando}
                        error={Boolean(errors.codMunicipio && errors.codMunicipio.message)}
                        helperText={
                          touchedFields.codMunicipio || errors.codMunicipio
                            ? errors.codMunicipio?.message
                            : undefined}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name='uf'
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={carregando}
                        conteudo={UFMock}
                        fullWidth
                        variant="outlined"
                        label={'UF'}
                        {...field}
                        value={UFMock.find(x => x.Value === (isEmpty(field.value) ? uf : field.value))?.Key}
                        onChange={(event) => {
                          if (event) {
                            const item = UFMock.filter(
                              (item) => item.Key === event.target.value,
                            )[0];
                            if (item) {
                              setValue('uf', item.Value);
                            }
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
