import { useTheme } from "@material-ui/core"

export const BackgroundHeaderRight = () => {
    const theme = useTheme()
    return (
        <svg width="211" height="341" viewBox="0 0 111 241" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M173.63 207.035C139.938 199.541 108.254 187.808 78.5775 171.835C49.3976 155.547 26.8934 137.974 11.0651 119.117L61.0769 52.2807C76.3642 69.7393 95.6888 85.3268 119.051 99.0432C142.413 112.76 166.677 122.417 191.845 128.015C219.854 134.244 241.459 134.79 256.661 129.651C271.952 124.106 281.133 114.432 284.203 100.631C286.46 90.4824 284.252 81.258 277.578 72.9574C271.4 64.3411 262.471 56.6039 250.79 49.7457C239.515 42.9778 223.865 34.8108 203.84 25.2447C173.081 10.309 148.208 -3.31745 129.221 -15.6348C110.234 -27.9521 95.1271 -44.3056 83.8998 -64.6953C73.0784 -84.9946 70.8277 -109.352 77.1479 -137.767C82.6554 -162.529 94.319 -183.365 112.139 -200.276C130.049 -217.593 153.709 -229.158 183.118 -234.971C212.934 -240.694 247.326 -239.221 286.296 -230.554C313.493 -224.504 339.359 -215.343 363.894 -203.07C388.429 -190.797 409.084 -176.404 425.86 -159.892L378.757 -91.77C344.348 -122.854 307.456 -142.775 268.081 -151.533C240.477 -157.672 218.985 -157.767 203.603 -151.816C188.627 -145.774 179.514 -135.447 176.263 -120.834C173.013 -106.22 178.085 -93.5898 191.479 -82.9424C205.369 -72.6107 227.176 -60.5184 256.897 -46.6654C287.656 -31.7298 312.529 -18.1033 331.516 -5.78598C350.503 6.53133 365.452 22.6367 376.364 42.5302C387.681 62.5139 390.18 86.7133 383.86 115.128C378.443 139.484 366.576 160.275 348.26 177.502C330.44 194.413 306.623 205.73 276.807 211.453C246.992 217.175 212.599 215.703 173.63 207.035Z" fill="url(#paint0_radial_2835_8076)" />
            <defs>
                <radialGradient id="paint0_radial_2835_8076" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.5 103) rotate(20.0321) scale(249.601 180.841)">
                    <stop stopColor={theme.palette.primary.main} stopOpacity="0.14" />
                    <stop offset="0.458726" stopColor={theme.palette.primary.main} stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    )
}
