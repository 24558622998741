export class FormStep {
    constructor(
        public formTitle: string,
        public formDesc: string,
        public formTitleIcon: JSX.Element,
        public stepLabel: string,
        public stepIcon: JSX.Element,
        public form: JSX.Element,
        public previousButton: JSX.Element | undefined,
        public nextButton: JSX.Element,
    ) { }
}
