import { Redirect, Route, Switch } from 'react-router-dom';
import { MainPage } from 'views/pages/private/main/main-page';
import { MenuPrincipalProvider } from 'services/app/providers/menu-principal-provider';
import MinhasIndicacoesPage from 'views/pages/private/minhas-indicacoes/minhas-indicacoes-page';
import ConfiguracoesPage from 'views/pages/private/configuracoes/configuracoes-page';

export const RouterMain = () => {
  return (
    <MenuPrincipalProvider>
      <MainPage>
        <Switch>

          {/*
              Sistemas 
             */}
          <Route path="/minhas-indicacoes" component={MinhasIndicacoesPage} />
          <Route path="/configuracoes" component={ConfiguracoesPage} />

          <Route path="/">
            <Redirect to="/minhas-indicacoes" />
          </Route>
        </Switch>
      </MainPage>
    </MenuPrincipalProvider>
  );
};
export default RouterMain;
