import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetValidarCadastro() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<boolean>();

    const getValidarCadastro = useCallback(
        (documento: string) =>
            invocarApi({
                url: `v1/Pessoa/${documento}/validar-cadastro`,
                method: 'GET',
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getValidarCadastro,
    };
}
