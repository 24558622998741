import { Box, Grid } from '@material-ui/core';
import { picker } from 'utils/picker';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect, useState } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus, useCadastros } from 'services/app';
import {
  VoltarIcon,
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { FormIndicacaoEdicao } from 'views/components/form/master/indicacao/indicacao-edicao/form-indicacao-edicao';
import { IndicacaoModel } from 'model/api/gestao/indicacao/indicacao-model';
import { useGetIndicacaoById, usePutIndicacao } from 'data/api/gestao/indicacao';
import { IndicacaoEdicaoConteudo } from './indicacao-edicao-conteudo/indicacao-edicao-conteudo';
import { IndicacaoAvaliacaoPutModel } from 'model/api/gestao/indicacao/indicacao-avaliacao-put-model';
import { TabSaurusContent, TabSaurusLabel, TabsSaurus } from 'views/components/tabs/tabs-saurus';
import { IndicacaoInteracao } from './indicacao-interacao/indicacao-interacao';
import { IndicacaoPutModel } from 'model/api/gestao/indicacao/indicacao-put-model';
import { EnumStatusIndicacao } from 'model/enums/enum-status-indicacao';

type Props = {
  id: string
}

export const IndicacaoEdicao = ({ id }: Props) => {

  const { putIndicacao, carregando: carregandoPut } = usePutIndicacao();
  const { getIndicacaoById, carregando: carregandoGet } = useGetIndicacaoById()

  const [preenchendoTela, setPreechendoTela] = useState<boolean>(false)

  const carregando =
    carregandoGet ||
    preenchendoTela

  const { fecharCadastroIndicacao } =
    useCadastros();
  const { showToast } = useToastSaurus();

  const refEditForm =
    useRef<DefaultFormRefs<IndicacaoAvaliacaoPutModel>>(null);
  const [indicacaoModel, setIndicacaoModel] = useState<IndicacaoModel>(new IndicacaoModel())
  const [abaSelecionada, setAbaSelecionada] = useState(0);
  const [indicacaoForm, setIndicacaoForm] = useState<IndicacaoAvaliacaoPutModel>(new IndicacaoAvaliacaoPutModel())

  const classes = useModalStyles();

  const getIndicacaoByIdWrapper = useCallback(async () => {
    const res = await getIndicacaoById(id)

    if (res.erro) throw res.erro

    if (!res.resultado) {
      return new IndicacaoModel()
    }

    return res.resultado?.data ?? new IndicacaoModel()
  }, [getIndicacaoById, id])

  const tabChange = (index: number) => {
    setAbaSelecionada(index);
  };

  const preencherTela = useCallback(async () => {

    if (abaSelecionada === 0) {
      try {
        setPreechendoTela(true)
        const indicacao = await getIndicacaoByIdWrapper()
        const form = picker<IndicacaoAvaliacaoPutModel>(indicacao, new IndicacaoAvaliacaoPutModel())
        setIndicacaoForm(form)
        setIndicacaoModel(indicacao)
      } catch (e: any) {
        showToast('error', e.message)
      } finally {
        setPreechendoTela(false)
      }
    }
  }, [abaSelecionada, getIndicacaoByIdWrapper, showToast])

  useEffect(() => {
    preencherTela()
  }, [preencherTela])

  const saveIndicacao = useCallback(
    async (model: IndicacaoPutModel) => {

      const ret = await putIndicacao(model);

      if (ret.erro) {
        throw ret.erro;
      }
    },
    [putIndicacao]
  );

  const handleSubmit = useCallback(
    async (model: IndicacaoAvaliacaoPutModel) => {
      try {

        const indicacao: IndicacaoPutModel = {
          id: indicacaoModel.id,
          avaliacaoIndicador: model.avaliacaoIndicador,
          motivoAvaliacaoIndicador: model.motivoAvaliacaoIndicador,
          indicadorId: indicacaoModel.indicador.id,
          data: indicacaoModel.data,
          avaliacaoResponsavel: 0,
          instrucao: indicacaoModel.instrucao,
          nivelDeInteresse: indicacaoModel.nivelDeInteresse,
          motivoAvaliacaoResponsavel: null,
          observacaoFinalizacao: null,
          responsavelId: indicacaoModel.responsavel?.id ?? null,
          sistemaId: indicacaoModel.sistema?.id ?? null,
          status: indicacaoModel.status
        }

        await saveIndicacao(indicacao);

        showToast('success', "Avaliação Enviada!")

        await preencherTela()

        refEditForm.current?.fillForm(model)
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [indicacaoModel.data, indicacaoModel.id, indicacaoModel.indicador.id, indicacaoModel.instrucao, indicacaoModel.nivelDeInteresse, indicacaoModel.responsavel?.id, indicacaoModel.sistema?.id, indicacaoModel.status, preencherTela, saveIndicacao, showToast],
  );

  const onCloseClick = useCallback(() => {
    fecharCadastroIndicacao()
  }, [fecharCadastroIndicacao]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Indicação'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <TabsSaurus
        selectedTabIndex={abaSelecionada}
        onChange={tabChange}
        tabsLabel={
          [
            new TabSaurusLabel('Dados', 0),
            new TabSaurusLabel('Interações', 1),
          ]
        }
        tabsContent={[
          new TabSaurusContent(
            0,
            (
              <div className={classes.content}>
                <div
                  className={classNames(
                    classes.contentForms,
                    carregando ? classes.contentFormsLoading : undefined,
                  )}
                >
                  <Box p={1}>
                    <Grid container spacing={2}>
                      {[EnumStatusIndicacao.Cancelado, EnumStatusIndicacao.InvalidezSolicitada, EnumStatusIndicacao.PropostaRecusada, EnumStatusIndicacao.PropostaAceita].includes(indicacaoModel.status) && (
                        <FormIndicacaoEdicao
                          loading={carregandoPut}
                          showLoading={false}
                          hasValor={indicacaoModel.avaliacaoIndicador > 0 || (indicacaoModel.motivoAvaliacaoIndicador ?? '').length > 0}
                          onSubmit={handleSubmit}
                          model={indicacaoForm}
                          ref={refEditForm}
                        />
                      )
                      }
                      <IndicacaoEdicaoConteudo
                        model={indicacaoModel}
                      />
                    </Grid>
                  </Box>
                </div>
              </div>
            ),
          ),
          new TabSaurusContent(
            1,
            (
              <IndicacaoInteracao abaSelecionada={abaSelecionada === 1} id={id}/>
            ),
          ),
        ]}
      />
    </div>
  );
};
