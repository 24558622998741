import { VariaveisAmbiente } from 'config';
import { ApiListModel } from 'model/api/gestao/api-list-model/api-list-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { IndicacaoModel } from 'model/api/gestao/indicacao/indicacao-model';

export function useGetIndicacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<IndicacaoModel>>();

    const getIndicacao = useCallback(
        (newPage: number, query?: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Indicacao?page=${newPage}&${query}`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getIndicacao,
    };
}
