import { useEffect, useState, useCallback } from "react";
import { Box, Grid } from "@material-ui/core";
import { useStyles } from "./minhas-indicacoes-list-styles";
import { MinhasIndicacoesListData } from "./minhas-indicacoes-list-data";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { MinhasIndicacoesListSearchProps } from "./minhas-indicacoes-list-search-props";
import { useHistory } from "react-router-dom";
import { IndicacaoModel } from "model/api/gestao/indicacao/indicacao-model";
import { useGetIndicacao } from "data/api/gestao/indicacao";
import { isEmpty } from "lodash";

export interface MinhasIndicacoesListProps {
  searchProps: MinhasIndicacoesListSearchProps;
}

export const MinhasIndicacoesList = (props: MinhasIndicacoesListProps) => {
  const classes = useStyles();
  const { getIndicacao, carregando } = useGetIndicacao();
  const { abrirCadastroIndicacao, cadastroIndicacaoState } = useCadastros()
  const { usuario } = useSessaoAtual()
  const { showToast } = useToastSaurus();
  const history = useHistory();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<IndicacaoModel>(),
  });

  const [modalEditAberto, setModalEditAberto] = useState(false);

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<IndicacaoModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    }, []);

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await getIndicacao(newPage, `pageSize=10&indicadorId=${usuario?.Id ?? ''}${!isEmpty(props.searchProps.termo)
          ? '&query=' + props.searchProps.termo
          : ''
          }`);
        if (res.erro) throw res.erro;

        if (!res.resultado) return
        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [getIndicacao, usuario?.Id, props.searchProps.termo, fillResult, showToast]
  );

  useEffect(() => {
    (async () => {
      if (cadastroIndicacaoState.aberto === false && modalEditAberto) {
        await search(queryStatus.page);
      }
      setModalEditAberto(cadastroIndicacaoState.aberto);
    })()
  }, [cadastroIndicacaoState.aberto, modalEditAberto, queryStatus.page, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const onCardSelected = (id: string) => {
    abrirCadastroIndicacao(id, history.location.pathname, true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  useEffect(() => {
    search(queryStatus.page);
  }, [queryStatus.page, search]);

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto'>
          <Grid container style={{
            flex: '0 1 auto',
            overflowY: 'auto'
          }}>
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.page}
              />
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <MinhasIndicacoesListData
                carregando={carregando}
                list={queryStatus.list}
                selectedList={selectedList}
                onCardSelected={onCardSelected}
                onCardChecked={onCardChecked}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};
