import { makeStyles } from "@material-ui/core";

export const useDefaultCadastroStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden"
    },
    header: {
        flex: "0 1 auto"
    },
    list: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto",
        position: "relative",
    },
    content: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
    },
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    acoes: {
        flex: "0 0 auto",
        padding: theme.spacing(2),
    },
}));
