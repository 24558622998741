import { Box, Card, Divider, Grid, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import { IndicacaoModel } from "model/api/gestao/indicacao/indicacao-model";
import { toDateString } from "utils/to-date";
import { useStyles } from "./indicacao-edicao-conteudo-styles";
import SemImagem from 'assets/img/sem-imagem.jpg';
import { useMemo } from "react";
import { useThemeQueries } from "views/theme";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { stringNumeros } from "utils/string-numeros";
import { phoneMask } from "utils/phone-mask";
import { formatarCEP } from "utils/formatar-cep";
import { StatusGeralIndicacoMock } from "data/mocks/status-geral-indicacao-mock";
import { useRetornarCorStatus } from "services/app/use-cases/retornar-cor-status";
import { ClienteIdeal } from "views/components/cliente-ideal/cliente-ideal";

type Props = {
    model: IndicacaoModel
}

export const IndicacaoEdicaoConteudo = ({ model }: Props) => {

    const classes = useStyles()
    const { theme } = useThemeQueries()
    const { retornarCorStatus } = useRetornarCorStatus()

    const img = useMemo(() => {
        const imagemUrl = model.sistema?.urlImagem
        try {
            if (imagemUrl.length > 0) {
                const url = atob(imagemUrl);
                return <img src={url} className={classes.cardImg} alt={model.sistema?.nome} />;
            }

            return (
                <img src={SemImagem} className={classes.cardImg} alt={model.sistema?.nome} />
            );
        } catch (err) {
            return (
                <img src={SemImagem} className={classes.cardImg} alt={model.sistema?.nome} />
            );
        }
    }, [classes.cardImg, model.sistema?.nome, model.sistema?.urlImagem]);

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="body1" style={{
                    width: '100%',
                    textAlign: 'center',
                    padding: '8px 12px',
                    background: retornarCorStatus(model.status),
                    borderRadius: theme.shape.borderRadius,
                    fontWeight: 600,
                    color: '#FFF',
                }}>
                    {StatusGeralIndicacoMock.find(x => x.Key === model.status)?.Value}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="primary" variant="h5">
                    <Box fontWeight={600}>
                        Dados
                    </Box>
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    Razão Social
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={600}>
                        {model.cliente.nomeComercial}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2">
                    Responsável
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.responsavel && model.responsavel.nomeComercial ? model.responsavel.nomeComercial : 'Sem Responsável'}
                    </Box>
                </Typography>
            </Grid>
            {model.responsavel && model.responsavel && model.responsavel.informacoes && <Grid item xs={6}>
                <Typography variant="body2">
                    Tel. Responsável
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.responsavel.informacoes.telefone}
                    </Box>
                </Typography>
            </Grid>}
            <Grid item xs={12}>
                <Typography variant="body2">
                    Agente
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.indicador.nomeComercial}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    E-mail
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.cliente.informacoes.email}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2">
                    Telefone
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {phoneMask(stringNumeros(model.cliente.informacoes.telefone))}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2">
                    CPF/CNPJ
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {formatarCPFCNPJ(model.cliente.cpfCnpj)}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    Data de Indicação
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {toDateString(model.data)}
                    </Box>
                </Typography>
            </Grid><Grid item xs={12}>
                <Box mt={3}>
                    <Typography color="primary" variant="h5">
                        <Box fontWeight={600}>
                            Acompanhamento
                        </Box>
                    </Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">
                    Última Visita
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.dataUltimaVisita ? toDateString(model.dataUltimaVisita) : "-"}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">
                    Última Retorno
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.dataUltimoRetorno ? toDateString(model.dataUltimoRetorno) : "-"}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">
                    Finalização
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.dataFinalizacao ? toDateString(model.dataFinalizacao) : "-"}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box mt={3}>
                    <Typography color="primary" variant="h5">
                        <Box fontWeight={600}>
                            Endereço
                        </Box>
                    </Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={9}>
                <Typography variant="body2">
                    Rua
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.cliente.informacoes.logradouro}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body2">
                    Nº
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.cliente.informacoes.numero}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    Bairro
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.cliente.informacoes.bairro}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="body2">
                    Município
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.cliente.informacoes.municipio}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">
                    Cód. Município
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.cliente.informacoes.codMunicipio}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography variant="body2">
                    CEP
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {formatarCEP(model.cliente.informacoes.cep)}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body2">
                    UF
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.cliente.informacoes.uf}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography variant="body2">
                    Complemento
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {
                            !isEmpty(model.cliente.informacoes.complemento)
                                ? model.cliente.informacoes.complemento
                                : 'Não Informado'
                        }
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box mt={3}>
                    <Typography color="primary" variant="h5">
                        <Box fontWeight={600}>
                            Interesse
                        </Box>
                    </Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    Nível de Interesse
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.nivelDeInteresse}%
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    Instrução
                </Typography>
                <Typography variant="body1">
                    <Box fontWeight={500}>
                        {model.instrucao}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box mt={3}>
                    <Typography color="primary" variant="h5">
                        <Box fontWeight={600}>
                            Aplicação
                        </Box>
                    </Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12}>
                {!isEmpty(model.sistema) ? (
                    <Card>
                        <Box p={2} display={"flex"} alignItems="center">
                            <Grid>
                                <Box paddingRight={2}>
                                    {img}
                                </Box>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {model.sistema?.nome}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {model.sistema?.descricao}
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                ) : (
                    <Card>
                        <Box p={2} display={"flex"} height={100} alignItems="center" width="100%" justifyContent="center">
                            <Typography>
                                Nenhum sistema escolhido.
                            </Typography>
                        </Box>
                    </Card>
                )}
            </Grid>
            <Grid item xs={12}>
                <Box mt={3}>
                    <Typography color="primary" variant="h5">
                        <Box fontWeight={600}>
                            Cliente Ideal
                        </Box>
                    </Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box display='flex' justifyContent='center'>
                    <ClienteIdeal pessoaId={model.cliente.id} status={model.status} />
                </Box>
            </Grid>

        </>
    )
}