import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./accordion-saurus-styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { ExpandBoldRoundIcon, ExpandIcon } from "views/components/icons";
import { useCallback } from "react";
import classNames from "classnames";
interface AccordionSaurusProps extends AccordionProps {
  tituloChildren?: React.ReactNode;
  expandChildren?: React.ReactNode;
  labelPrimary?: string;
  labelSecondary?: string;
  showDivider?: boolean;
  colorDivider?: string;
  heightDivider?: number;
  noIconRotation?: boolean;
  noPaperRoot?: boolean;
  noPaddingContent?: boolean;
  tipoExpand?: "padrao" | "bold";
  colorExpand?: string;
  expandSize?: number;
}

export const AccordionSaurus = ({
  tituloChildren,
  expandChildren,
  labelPrimary,
  labelSecondary,
  showDivider,
  colorDivider,
  heightDivider,
  noPaperRoot,
  noPaddingContent,
  tipoExpand,
  colorExpand,
  expandSize,
  ...props
}: AccordionSaurusProps) => {
  const classes = useStyles();

  const selectExpand = useCallback(() => {
    switch (tipoExpand) {
      case "bold":
        return (
          <ExpandBoldRoundIcon
            style={{ width: 25, height: 25, fill: colorExpand ?? undefined }}
          />
        );
      default:
        return (
          <ExpandIcon
            style={{ width: 25, height: 25, fill: colorExpand ?? undefined }}
          />
        );
    }
  }, [tipoExpand, colorExpand]);

  // const accProps = props;
  // delete accProps.expandChildren;
  // delete accProps.titulo;

  return (
    <>
      <Accordion
        {...props}
        classes={{
          root: classNames(noPaperRoot
            ? classes.rootAccordionNoPaper
            : classes.rootAccordion,
            classes.iconWithoutRotation
          ),
          expanded: classes.expanded,
        }}
      >
        <AccordionSummary
          classes={{
            root: noPaddingContent
              ? classes.accordionContentNoPadding
              : classes.accordionContent,
            content: noPaddingContent
              ? classes.accordionSumaryContentNoPadding
              : classes.accordionSumaryContent,
          }}
          expandIcon={expandChildren ? <>{expandChildren}</> : <>{selectExpand()}</>}
        >
          <Grid
            className={classes.titulo}
            style={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            {tituloChildren && <>{tituloChildren}</>}
            {labelPrimary && (
              <Typography display="inline" variant="h6" color="textPrimary">
                {labelPrimary}
              </Typography>
            )}
            {labelSecondary && (
              <Box ml={1} mt={1} display="inline">
                <Typography
                  display="inline"
                  variant="caption"
                  color="textSecondary"
                >
                  {labelSecondary}
                </Typography>
              </Box>
            )}
            {showDivider && (
              <div
                className={classes.divider}
                style={{
                  borderBottomWidth: heightDivider ? heightDivider : 0.9,
                  borderBottomColor: colorDivider,
                }}
              ></div>
            )}
          </Grid>

          <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            label=""
            control={<></>}
          />
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: noPaddingContent
              ? classes.accordionContentNoPadding
              : classes.accordionContent,
          }}
        >
          {props.children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
