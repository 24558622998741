import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PessoaInfoModel } from 'model/app/forms/master/pessoa-info-model';

export function usePutAtualizarPessoaInfo() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarPessoaInfo = useCallback(
        (info: PessoaInfoModel, pessoaId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Pessoa/${pessoaId}/info`,
                method: 'PUT',
                data: info,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarPessoaInfo,
    };
}
