import { KeyValueModel } from "model";
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';


export const TpPessoasMock: KeyValueModel[] = [
    new KeyValueModel(EnumTipoPessoas.SoftwareHouse, "SoftwareHouse"),
    new KeyValueModel(EnumTipoPessoas.Revendedor, "Revendedor"),
    new KeyValueModel(EnumTipoPessoas.Cliente, "Cliente"),
    new KeyValueModel(EnumTipoPessoas.Funcionario, "Funcionário"),
    new KeyValueModel(EnumTipoPessoas.Prospeccao, "Prospecção"),
    new KeyValueModel(EnumTipoPessoas.AgenteExterno, "Agente Externo"),
    new KeyValueModel(EnumTipoPessoas.GerenteComercial, "Gerente Comercial"),
    new KeyValueModel(EnumTipoPessoas.FuncionarioFinanceiro, "Funcionário Financeiro")
];
