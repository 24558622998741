import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { SaurusTheme } from './views/theme/saurus-theme';
import { ConfirmProvider } from 'material-ui-confirm';
import { RouterApp } from 'routers';
import toast, { ToastBar, Toaster, useToasterStore } from 'react-hot-toast';
import './assets/css/join.css';
import { useThemeQueries } from 'views';
import { SessaoAtualProvider } from 'services/app';
import { BrowserRouter, Switch } from 'react-router-dom';
// import { Manutencao } from 'views/components/manutencao/manutencao';


(window as any).process = {};

//POLYFILL DO globalThis (CONTEXTO PARA NAVEGADOR ANTIGO)
if (!(window as any).globalThis) {
  (window as any).globalThis = (function () {
    return (window as any);
  });
}

const HotToast = () => {
  const { toasts } = useToasterStore();
  const { isMobile } = useThemeQueries();

  const TOAST_LIMIT = 1;

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.remove(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  return (
    <Toaster
      toastOptions={{
        style: {
          width: '100%',
        },
        duration: 3000,
      }}
      containerStyle={{
        top: 60,
        left: 16,
        bottom: isMobile ? 65 : 60,
        right: 16,
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => {
            return (
              <div
                onClick={() => toast.dismiss(t.id)}
                style={{ display: 'flex', height: '100%', width: '100%' }}
              >
                {icon}
                {message}
              </div>
            );
          }}
        </ToastBar>
      )}
    </Toaster>
  );
};

if (!Object.entries) {
  Object.entries = function (obj: any) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

const renderReactDom = () => {
  ReactDOM.render(
    <>
      <BrowserRouter>
        <Switch>
          <SessaoAtualProvider>
            <ThemeProvider
              theme={
                SaurusTheme
              }
            >
              <CssBaseline>
                <HotToast />
                <ConfirmProvider
                  defaultOptions={{
                    title: 'Confirma a Operação?',
                    cancellationButtonProps: {
                      variant: 'text',
                      color: 'secondary',
                    },
                    confirmationButtonProps: {
                      variant: 'text',
                    },
                    confirmationText: 'Sim',
                    cancellationText: 'Não',
                  }}
                >
                  {/* <Manutencao mensagem="Estamos em manutenção no momento. O serviço de Indicações voltará dia 25/07."/> */}
                  <RouterApp/>
                </ConfirmProvider>
              </CssBaseline>
            </ThemeProvider>
          </SessaoAtualProvider>
        </Switch>
      </BrowserRouter>
    </>,
    document.getElementById('react-root'),
  );
};

if ((window as any).cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  renderReactDom();
}
