import { Grid } from "@material-ui/core";
import { IndicacaoModel } from "model/api/gestao/indicacao/indicacao-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardIndicacao } from "views/components/cards/card-indicacao";
import { InformacaoIcon } from "views/components/icons";

export interface MinhasIndicacoesGridProps {
  list: Array<IndicacaoModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const MinhasIndicacoesListData = (props: MinhasIndicacoesGridProps) => {

  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhuma Indicação Encontrada."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      {props.list.length > 0 &&
        props.list.map((indicacao, index) => {
          return (
            <Grid item xs={12}>
              <CardIndicacao
                selected={
                  props.selectedList.filter((item) => item === indicacao.id).length >
                    0
                    ? true
                    : false
                }
                onCheck={onCardChecked}
                onClick={onCardSelected}
                model={indicacao}
                key={index}
              />
            </Grid>
          );
        })}
    </>
  );
};
