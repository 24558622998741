import { Box, Card, Grid, Typography } from "@material-ui/core"
import { useInteracaoChatStyles } from "./interacao-chat-styles"
import classNames from "classnames"
import { useCallback } from "react"
import { useSessaoAtual } from "services/app"
import { InteracoesModel } from "model/api/gestao/interacoes/interacoes-model"
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao"
import { StatusGeralIndicacoMock } from "data/mocks/status-geral-indicacao-mock"
import { isEmpty } from "lodash"
import { useRetornarCorStatus } from "services/app/use-cases/retornar-cor-status"

type Props = {
    model: InteracoesModel;
    index: number;
    array: InteracoesModel[];
    scrollRef: React.MutableRefObject<HTMLElement | null>;
}

export const InteracaoChat = ({ model, array, index, scrollRef, ...props }: Props) => {

    const classes = useInteracaoChatStyles()
    const { tipoUsuario } = useSessaoAtual()
    const { retornarCorStatus } = useRetornarCorStatus();

    const pegaHora = useCallback((data: string) => {
        const date = new Date(data)
        return `${('00' + date.getHours()).substr(-2)}:${('00' + date.getMinutes()).substr(-2)}`
    }, [])

    const souEu = model.responsavel.tipo === tipoUsuario()


    const mostrarStatus = (index > 0 &&
        array[index - 1].tipoInteracao !== model.tipoInteracao &&
        model.tipoInteracao !== EnumStatusIndicacao.RetornoInterno
    )

    return (
        <>

            <Grid item xs={12} innerRef={scrollRef}>
                {!isEmpty(model.descricao) && <Box width="100%" display={"flex"} justifyContent={souEu ? "flex-end" : "flex-start"} style={{
                    paddingLeft: '8px',
                    paddingBottom: '8px'
                }}>
                    <div className={classes.containerBalao}>
                        {(index === 0 || array[index - 1].responsavel.id !== model.responsavel.id) &&
                            <div className={classNames(souEu ? classes.speechPrimeiro : classes.speechSegundo)}></div>
                        }

                        <Card className={classNames(souEu ? classes.balaoDourado : classes.balaoCinza)} style={{
                            minWidth: '100px',
                            padding: '8px',
                            position: 'relative',
                            boxShadow: "none",
                        }}>
                            {(index === 0 || array[index].responsavel.id !== array[index - 1].responsavel.id) &&
                                <Typography
                                    variant="body1"
                                    align={"left"}
                                >
                                    <Box fontWeight={600}>
                                        {souEu ? "Você" : model.responsavel.nomeComercial}
                                    </Box>
                                </Typography>
                            }
                            <Typography
                                variant="body1"
                                style={{ width: '100%' }}
                            >
                                <Box fontWeight={500}>
                                    {model.descricao}
                                </Box>
                            </Typography>
                            <Typography variant="caption" align="right">
                                <Box fontWeight={500}>
                                    {pegaHora(model.dataHora)}
                                </Box>
                            </Typography>
                        </Card>
                    </div>
                </Box>}

                {mostrarStatus && <Box width="100%" display="flex" justifyContent={"center"} style={{ padding: '8px' }}>
                    <Box
                    // width={`calc(100% - ${isMobile ? '16px' : '100px'})`}
                    >
                        <Card className={classes.balaoAviso}>
                            <Box>
                                <Typography variant="body2" align="center">
                                    <Box fontWeight={600}>
                                        {model.responsavel.nomeComercial} mudou o status para <span style={{
                                            color: retornarCorStatus(model.tipoInteracao),
                                            fontWeight: 700,
                                        }}>{
                                                StatusGeralIndicacoMock
                                                    .find(x => x.Key === model.tipoInteracao)?.Value.toUpperCase()}</span> às {pegaHora(model.dataHora)}
                                    </Box>
                                </Typography>
                                {/* <Typography align="center" variant="body2">
                                    <Box mt={1}>
                                        {model.descricao}
                                    </Box>
                                </Typography> */}
                            </Box>
                        </Card>
                    </Box>
                </Box>}
            </Grid>
        </>
    )
}