import { EnumMenu } from 'model';
import { MenuModel } from '../../model/app';

export const ModulosMock: MenuModel[] = [

  new MenuModel('Clientes', EnumMenu.INDICACAO_GROUP, [], '', 1),
  new MenuModel('Configurações', EnumMenu.CONFIGURACOES_GROUP, [], '', 2),

  new MenuModel('Minhas Indicações', EnumMenu.INDICACAO, [], '/minhas-indicacoes', 2, EnumMenu.INDICACAO_GROUP),
  new MenuModel('Configurações', EnumMenu.CONFIGURACOES, [], '/configuracoes', 3, EnumMenu.CONFIGURACOES_GROUP),
];
