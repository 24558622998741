import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    defaultContainer: {
        position: "relative",
        width: '100%',
        margin: '0 11rem',
        borderRadius: 8,
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down('lg')]: {
            margin: '0 10rem',
        },
        [theme.breakpoints.down('md')]: {
            margin: '0 1rem',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0 .5rem'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0'
        }
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    list: {
        overflowX: "hidden",
        overflow: "auto",
        position: "relative",
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    contentForms: {
        flex: "1 1 auto",
        overflowX: "hidden",
        overflowY: "auto",
        "& * > form": {
            visibility: "visible",
        },
        "&  .tabs-class": {
            visibility: "visible",
        },
    },
    contentFormsLoading: {
        "& * > form": {
            visibility: "hidden",
        },

        "&  .tabs-class": {
            visibility: "hidden",
        },
    },
    acoes: {
        flex: "0 0 auto",
        padding: '0 10px',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    content: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
    },
}));