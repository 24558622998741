import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  rootAccordionNoPaper: {
    boxShadow: "unset",
    paddingLeft: "0px",
    paddingRight: "0px",
    background: 'transparent',
  },
  rootAccordion: {
    boxShadow: '1px 2px 3px 1px #00000030'
  },
  titulo: {
    "& svg": {
      width: 24,
    }
  },
  accordionContent: {},
  accordionContentNoPadding: {
    paddingLeft: "0px",
    paddingRight: "0px",
    textTransform: "capitalize"
  },
  accordionSumaryContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  accordionSumaryContentNoPadding: {},
  expanded: {
    height: "100%",
  },
  divider: {
    marginTop: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
  },
  iconWithoutRotation: {
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      // transform: "rotate(0deg)",
    }
  }
}));
