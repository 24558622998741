import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

  root: {
    margin: theme.spacing(1),
    marginBottom: 0,
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },

  navContainer: {
    display: "flex",
    // flexDirection: "row",
    // flexWrap: "wrap",
    borderSpacing: '10px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    }
  },
  navContainerInterno: {
    minHeight: '30px',
    borderRadius: 5,
    boxShadow: theme.shadows[1],
    padding: 3,
    paddingLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // flexWrap: "nowrap",
    background: theme.palette.common.white,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      maxHeight: '50px'
    }
  },
  paginaContainer: {
    minHeight: '30px',
    boxShadow: theme.shadows[1],
    borderRadius: 5,
    padding: 3,
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
    background: theme.palette.common.white,
  },
  sumaryContainer: {
    minHeight: '30px',
    boxShadow: theme.shadows[1],
    borderRadius: 5,
    display: 'flex', 
    alignItems: 'center',
    padding: 10,
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    "& strong": {
      paddingLeft: theme.spacing(1)
    }
  },

  ul: {
    margin: "0",
    display: "flex",
    padding: "0",
    flexWrap: "nowrap",
    listStyle: "none",
    alignItems: "center",
  },

  btnNavPagina: {
    cursor: "pointer",
    height: "30px",
    minWidth: "30px",
    marginRight: theme.spacing(1),
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    textAlign: "center",
    lineHeight: '30px',
    letterSpacing: "0.01071em",

  },

  btnNavPaginaSelected: {
    borderRadius: '100%',
    pointerEvents: "none",
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    padding: '0 5px'
  },

  gridBtnTxtPagina: {
    float: "right",
    display: "flex",
    flexDirection: "row",

  },
  btnTxtPagina: {
    width: '30px',
    height: '30px',
    borderRadius: "100%",
    padding: 0,
    "& svg": {
      width: '20px',
      height: '20px',
      fill: theme.palette.text.primary
    }
  },
  txtFieldPagina: {
    width: 150,
    marginTop: -5,
    marginRight: theme.spacing(1)
  },

  btnNavAction: {
    cursor: "pointer",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    borderRadius: '100%',
    height: '30px',
    width: '30px',
    marginRight: theme.spacing(1),
    "& svg": {
      width: '18px',
      height: '18px',
      fill: theme.palette.text.primary
    }
  },

  svgIconActionDisabled: {
    "pointer-events": "none",
    "& svg": {
      opacity: 0.4,
    }
  },

}));

