import { guidEmpty } from "utils/guid-empty";

export class IndicacaoPostModel {
    constructor(
        public id: string = guidEmpty(),
        public indicadorId: string = guidEmpty(),
        public responsavelId: string = guidEmpty(),
        public nivelDeInteresse: number = 0,
        public sistemaId: string = '',
        public data: string = '',
        public instrucao: string = '',
        public cpfCnpj: string = '',
        public nomeComercial: string = '',
        public contato: string = '',
        public email: string = '',
        public telefone: string = '',
        public informacao: string = '',
        public fantasia: string = '',
        public logradouro: string = '',
        public cep: string = '',
        public numero: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public codMunicipio: number = 0,
        public uf: string = '',
        public complemento: string = '',
        public faturamentoEstimado: number = 0,
    ) { }
}
