import * as Yup from 'yup';
import { useValidationYupFone } from '../../../form-validations'
export const useFormRegistrarInfoValidation = () => {
    const telefoneYup = useValidationYupFone()

    const FormRegistrarInfoYupValidation = Yup.object().shape({
        telefone: telefoneYup().required('Telefone é obrigatório').typeError('Telefone é obrigatório')
    });

    return {
        FormRegistrarInfoYupValidation
    }
}