import { useCallback } from 'react';
import * as Yup from 'yup';

export const useValidationYupEmail = () => {
    const emailYup = useCallback(() => {
        return (
            Yup.string().email('O e-mail informado está inválido').required('O e-mail é obrigatório.')
        )
    }, [])
    return {
        emailYup
    }
}
