import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";
import { getCurrentDateTime } from "utils/formatar-data";
import { guidEmpty } from "utils/guid-empty";

export class InteracoesPostModel {
    constructor(
        public id: string = guidEmpty(),
        public indicacaoId: string | null = null,
        public responsavelId: string | null = null,
        public tipoInteracao: number = EnumStatusIndicacao.RetornoInterno,
        public descricao: string = '',
        public dataHora: string = getCurrentDateTime(new Date()),
    ) { }
}
