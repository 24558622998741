import { useStyles } from "./loading-button-styles";

type ColorLoad = | "PRIMARY" | "BRANCO"

interface Props {
    tipo: ColorLoad
}

export const LoadingButton = (props: Props) => {
    const classes = useStyles();
    const myClass = props.tipo === "PRIMARY" ? classes.loadAzul : classes.loadBranco

    return (
        <div className={`${classes.cLoader} ${myClass}`}></div>
    )
}
