import { Box, Button, Grid, } from "@material-ui/core"
import { PessoaPostModel } from "model/api/gestao/pessoa/pessoa-post-model";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { picker } from "utils/picker";
import { ImagemInput, TextFieldSaurus } from "views/components/controles/inputs";
import { PessoaIcon } from "views/components/icons/pessoa-icon";
import { CircularLoading } from "views/components/utils"
import { makeUtilClasses, useThemeQueries } from "views/theme"
import { DefaultFormProps, DefaultFormRefs } from "../../utils";
import ImagemPadrao from '../../../../../assets/img/usuario-sem-imagem.png'
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { useFormPessoaEditValidation } from "./form-editar-pessoa-validation";
import { yupResolver } from "@hookform/resolvers/yup";

export interface FormEditarPessoaProps extends DefaultFormProps<PessoaPostModel> {

}

export const FormEditarPessoa = forwardRef<DefaultFormRefs<PessoaPostModel>, FormEditarPessoaProps>(({ loading, onSubmit, ...props }: FormEditarPessoaProps, ref) => {
    const utilClasses = makeUtilClasses();
    const { isMobile } = useThemeQueries();
    const { FormPessoaEditValidationYup } = useFormPessoaEditValidation();

    const [model, setModel] = useState<PessoaPostModel>(new PessoaPostModel())

    const carregando = false;

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getValues,
    } = useForm<PessoaPostModel>({
        defaultValues: { ...model },
        resolver: yupResolver(FormPessoaEditValidationYup),
        criteriaMode: 'all',
        mode: 'onBlur' && 'onChange',
    });

    const submit = (values: PessoaPostModel) => {
        const pessoa = picker<PessoaPostModel>(values, new PessoaPostModel())
        onSubmit(pessoa, model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(submit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (values: PessoaPostModel) => {
            setModel(values)
            reset({ ...values })
        },
    }));

    const imagemBase64 = useCallback(() => {
        try {
            return atob(getValues('urlImagem'))
        } catch (e: any) {
            return getValues('urlImagem')
        }
    }, [getValues])

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer} style={{
                position: 'relative'
            }}>
                {carregando ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(submit)}
                    className={carregando ? utilClasses.controlLoading : ''}
                    style={{ padding: !isMobile ? '10px' : '0' }}
                >
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box mb={3}>
                                <Controller
                                    name="urlImagem"
                                    control={control}
                                    render={({ field }) => (
                                        <ImagemInput
                                            loadErrorImage={ImagemPadrao}
                                            accept='image/*'
                                            imageRadius="50%"
                                            width={isMobile ? '180px' : '200px'}
                                            height={isMobile ? '180px' : '200px'}
                                            error={Boolean(
                                                errors.urlImagem && errors.urlImagem.message,
                                            )}
                                            helperText={
                                                touchedFields.urlImagem || errors.urlImagem
                                                    ? errors.urlImagem?.message
                                                    : undefined
                                            }
                                            {...field}
                                            value={imagemBase64()}
                                            onChange={({ base64 }: any) => {
                                                setValue('urlImagem', base64);
                                            }}
                                            onBlur={() => {
                                                setValue('urlImagem', '');
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldSaurus
                                placeholder="CPF/CNPJ"
                                readOnly={true}
                                size='medium'
                                value={formatarCPFCNPJ(getValues('cpfCnpj'))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="nomeComercial"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        placeholder="Nome"
                                        size='medium'
                                        startAdornmentButton={
                                            <PessoaIcon tipo="INPUT" />
                                        }
                                        error={Boolean(
                                            errors.nomeComercial && errors.nomeComercial.message,
                                        )}
                                        helperText={
                                            touchedFields.nomeComercial || errors.nomeComercial
                                            ? errors.nomeComercial?.message 
                                            : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    )
},
);