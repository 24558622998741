import { VariaveisAmbiente } from 'config';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutAtualizarPessoa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarPessoa = useCallback(
        (pessoa: PessoaPostModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Pessoa`,
                method: 'PUT',
                data: pessoa,
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarPessoa,
    };
}
