import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import {
  Box,
  Button,
  Card,
  Grid,
  Slide,
  Typography,
} from '@material-ui/core';
import { CircularLoading, useThemeQueries } from 'views';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import { LoginIcon, VoltarIcon } from 'views/components/icons';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { makeUtilClasses } from '../../../theme/util-make-styles';
import { useStyles } from './registrar-page-styles'

import Logo from '../../../../assets/img/logo-licenciamento.svg'
import { FormRegistrar } from 'views/components/form/master/registrar/form-registrar';
import { RegistrarFormModel } from 'model/app/forms/master/registrar-form';
import { usePostIdentidadeNovoUsuario } from 'data/api/gestao/identidade/novo-usuario';
import { stringNumeros } from 'utils/string-numeros';
import { FormRegistrarInfo } from 'views/components/form/master/form-registrar-info/form-registrar-info';
import { PessoaInfoModel } from 'model/app/forms/master/pessoa-info-model';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { isEmpty } from 'lodash';
import { usePostAdicionarPessoaInfo } from 'data/api/gestao/pessoa-info/post-adicionar-pessoa-info';

type LocationProps = {
  cpfCnpj: string
}

const RegistrarPage = () => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const [bloqueado, setBloqueado] = useState(false);
  const registrarRefs = useRef<DefaultFormRefs<RegistrarFormModel>>(null);
  const infoRefs = useRef<DefaultFormRefs<PessoaInfoModel>>(null)
  const location = useLocation<LocationProps>()
  const { theme } = useThemeQueries()
  const [iniciou, setInicou] = useState<boolean>(false)
  const [fase, setFase] = useState<1 | 2>(1)
  const { postIdentidadeNovoUsuario, carregando: carregandoRegistro } = usePostIdentidadeNovoUsuario()
  const { postAdicionarPessoaInfo, carregando: carregandoInfo} = usePostAdicionarPessoaInfo();
  const { logar } = useSessaoAtual()

  const carregando = carregandoInfo || carregandoRegistro

  const { cpfCnpj } = location.state

  const modelRef = useRef<RegistrarFormModel>(new RegistrarFormModel());

  useEffect(() => {
    setInicou(true)
    if (registrarRefs) registrarRefs.current?.fillForm(new RegistrarFormModel('', '', ''));
  }, []);

  const proximaFase = useCallback((model: RegistrarFormModel) => {
    modelRef.current = model;
    setFase(2)
  }, [])

  const efetuarRegistro = useCallback(async (model: PessoaInfoModel) => {
    try {
      const res = await postIdentidadeNovoUsuario({
        cpfCnpj: stringNumeros(cpfCnpj),
        nomeComercial: modelRef.current.nomeComercial,
        senha: modelRef.current.senha,
        tipo: EnumTipoPessoas.AgenteExterno
      })

      if (res.erro) {
        throw res.erro
      }
      await logar(stringNumeros(cpfCnpj), modelRef.current.senha)

      if(!isEmpty(res.resultado?.data) && res.statusCode === 200){
        
        model.isComercial = true;
        model.xNome = modelRef.current.nomeComercial;
        model.fantasia = modelRef.current.nomeComercial;

        await postAdicionarPessoaInfo(res.resultado!.data.id, model)
      }

      showToast('success', 'Novo acesso registrado. Aproveite nosso sistema!')
      history.push('/');
      setBloqueado(true);
    } catch (e: any) {
      showToast('error', e.message);
      registrarRefs.current?.resetForm();
    }
  }, [cpfCnpj, history, logar, postAdicionarPessoaInfo, postIdentidadeNovoUsuario, showToast]);

  const switchForm = useMemo(() => {
    switch (fase) {
      case 1:
        return (
          <>
            <Grid item xs={12}>
              <Box display={"flex"} justifyContent={"center"} width={"100%"} className={classes.cpfCnpj}>
                <Typography>
                  <span style={{ color: theme.palette.primary.main }}>CPF/CNPJ:</span> {cpfCnpj}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} style={{ width: '100%' }}>
              <FormRegistrar
                showLoading={false}
                ref={registrarRefs}
                loading={carregando}
                onSubmit={(model) => {
                  proximaFase(model)
                }}
              />
              <Box mt={7}>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth={true}
                    disabled={carregando || bloqueado}
                    onClick={() => {
                      registrarRefs.current?.submitForm();
                    }}
                  >
                    <LoginIcon tipo="BUTTON_PRIMARY" />
                    Prosseguir
                  </Button>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    fullWidth={true}
                    disabled={carregando || bloqueado}
                    onClick={() => {
                      history.push('/login')
                    }}
                  >
                    <VoltarIcon tipo="BUTTON" />
                    Voltar
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </>
        )
      case 2:
        infoRefs.current?.fillForm(new PessoaInfoModel());
        return (
          <>
            <Grid item xs={12} style={{ width: '100%' }}>
              <FormRegistrarInfo
                showLoading={false}
                ref={infoRefs}
                loading={carregando}
                onSubmit={(model) => {
                  efetuarRegistro(model)
                }}
              />
              <Box mt={7}>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth={true}
                    disabled={carregando || bloqueado}
                    onClick={() => {
                      infoRefs.current?.submitForm();
                    }}
                  >
                    <LoginIcon tipo="BUTTON_PRIMARY" />
                    Criar Acesso
                  </Button>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    fullWidth={true}
                    disabled={carregando || bloqueado}
                    onClick={() => {
                      setFase(1)
                    }}
                  >
                    <VoltarIcon tipo="BUTTON" />
                    Voltar
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </>
        )
    }
  }, [bloqueado, carregando, classes.cpfCnpj, cpfCnpj, efetuarRegistro, fase, history, proximaFase, theme.palette.primary.main])

  return (
    <>
      <div className={`${utilClasses.contentCenter} ${classes.rootPadding}`}>
        <img src={Logo} alt='Logotipo Licenciamento' className={classes.logo} />
        <Grid container className={utilClasses.rootContainer}>
          {carregando || bloqueado ? (
            <CircularLoading tipo="FULLSIZED" />
          ) : null}
          <Slide in={iniciou || fase === 2} direction='left'>
            <Grid item xs={12}>
              <Card className={classes.loginCard}>
                <Grid container direction="column">
                  <Grid item xs={12} className={classes.textContainer}>
                    <Typography align="left" variant="h4" color="primary" className='titulo'>
                      Novo Acesso
                    </Typography>
                    <Typography variant='body1' color='textSecondary'>
                      Preencha o resto do formulário para poder acessar o aplicativo.
                    </Typography>
                  </Grid>
                  {switchForm}
                </Grid>
              </Card>
            </Grid>
          </Slide>
        </Grid>
      </div>
    </>
  );
};

export default RegistrarPage;
