import { useCallback } from "react";
import { useApiBase } from "../base/api-base";
import { VariaveisAmbiente } from "config/enviroment";

export function usePostImagemBase64() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postImagemBase64 = useCallback(
        (base64Imagem: string, path: string, nome: string) => {
            return invocarApi({
                url: `/v1/upload/imagem/base64`,
                method: "POST",
                enviarTokenUsuario: false,
                headers: {
                    "Authorization": "Saurus: JEBVcnVTX3Rva2Vu",
                    'Content-Type': 'application/json',
                    'redimensionar': 'true',
                    'path': path,
                    'nome': nome,
                },
                data: `"${base64Imagem}"`,
                baseURL: `${VariaveisAmbiente.apiImagemUrl}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postImagemBase64,
    };
}
