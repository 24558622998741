import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PessoaModel } from 'model/api/gestao/pessoa/pessoa-model';

export class IdentidadeRegistrarProps {
    public constructor(
        public nomeComercial: string = '',
        public cpfCnpj: string = '',
        public senha: string = '',
        public tipo: number = 4
    ) { }
}

export function usePostIdentidadeNovoUsuario() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoaModel>();
    const softwareHouseId = 'ed9e0f05-21cd-43a5-a9fa-230aed18d88a'

    const postIdentidadeNovoUsuario = useCallback(
        (autenticacaoProps: IdentidadeRegistrarProps) =>
            invocarApi({
                method: 'POST',
                url: `v1/Identidade/${softwareHouseId}/novo-usuario`,
                data: autenticacaoProps,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeNovoUsuario,
    };
}
