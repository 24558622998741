import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PessoaClienteGetModel } from 'model/api/gestao/pessoa-cliente-ideal/pessoa-cliente-model';


export function useGetPessoaClienteIdeal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<PessoaClienteGetModel>();

    const getPessoaClienteIdeal = useCallback(
        (pessoaId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/pessoa/${pessoaId}/cliente-ideal`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getPessoaClienteIdeal,
    };
}