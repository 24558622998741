import { EnumTipoComunicacao } from "model/enums";
import { EnumAmbientePagamento } from "model/enums/enum-ambiente-pagamento";
import { EnumInstituicao } from "model/enums/enum-instituicao";
import { guidEmpty } from "utils/guid-empty";

export class PagamentoFormModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = '',
    public tipoComunicacao: EnumTipoComunicacao = EnumTipoComunicacao.Boleto,
    public instituicao: EnumInstituicao = EnumInstituicao.Itau,
    public credenciais: TouchoneItauCredenciarBoletoModel |
      TouchoneItauCredenciarPixModel |
      TouchoneBancoDoBrasilCredenciarBoletoModel |
      TouchoneBancoDoBrasilCredenciarPixModel |
      null = null,
  ) { }
}

export class TouchoneContaBancoModel {
  constructor(
    public agencia: string = "",
    public conta: string = "",
    public digitoConta: number = 0,
    public digitoAgencia: number | undefined = undefined,
    public cpfCnpj: string = "",
    public nome: string = ""
  ) { }
}

export class TouchoneCertificadoModel {
  constructor(
    public conteudo: string = "",
    public senha: string = ""
  ) { }
}

export class TouchoneClientCredentialsModel {
  constructor(
    public id: string = "",
    public secret: string = ""
  ) { }
}

//--- ITAU --

export class TouchoneItauCredenciarBoletoModel {
  constructor(
    public instituicao: EnumInstituicao = EnumInstituicao.Itau,
    public ambiente: EnumAmbientePagamento = EnumAmbientePagamento.Sandbox,
    public conta: TouchoneContaBancoModel | undefined = undefined,
    public certificado: TouchoneCertificadoModel | undefined = undefined,
    public credenciais: TouchoneClientCredentialsModel | undefined = undefined
  ) { }
}

export class TouchoneItauCredenciarPixModel {
  constructor(
    public instituicao: EnumInstituicao = EnumInstituicao.Itau,
    public ambiente: EnumAmbientePagamento = EnumAmbientePagamento.Sandbox,
    public chave: string = "",
  ) { }
}


// -- BANCO DO BRASIL

export class TouchoneBancoDoBrasilCredenciarBoletoModel {
  constructor(
    public instituicao: EnumInstituicao = EnumInstituicao.BANCO_DO_BRASIL,
    public ambiente: EnumAmbientePagamento = EnumAmbientePagamento.Sandbox,
    public conta: TouchoneContaBancoModel | undefined = undefined,
    public basic: string = "",
    public convenio: string = "",
    public carteira: string = "",
    public variacao: string = "",
    public appKey: string = ""
  ) { }
}

export class TouchoneBancoDoBrasilCredenciarPixModel {
  constructor(
    public instituicao: EnumInstituicao = EnumInstituicao.Itau,
    public chave: string = "",
  ) { }
}
