import * as Yup from 'yup';
import { useValidationYupSenha, useValidationYupSenhaConfirmacao } from '../../../form-validations'
export const useFormRegistrarValidation = () => {
    const { senhaYup } = useValidationYupSenha()
    const { senhaConfirmacaoYup } = useValidationYupSenhaConfirmacao()

    const FormRegistrarYupValidation = Yup.object().shape({
        nomeComercial: Yup.string().required('Nome Comercial é obrigatório.'),
        senha: senhaYup(),
        confirmarSenha: senhaConfirmacaoYup("senha")
    });

    return {
        FormRegistrarYupValidation
    }
}

