import { Box, Button, Divider, Grid } from '@material-ui/core';
import { useRef } from 'react';
import { useFormRegistrarInfoValidation } from './form-registrar-info-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PessoaInfoModel } from 'model/app/forms/master/pessoa-info-model';
import { useStyles } from '../registrar/form-registrar-styles';
import { picker } from 'utils/picker';

export const FormRegistrarInfo = React.forwardRef<
  DefaultFormRefs<PessoaInfoModel>,
  DefaultFormProps<PessoaInfoModel>
>(({ ...props }: DefaultFormProps<PessoaInfoModel>, refs) => {
  const txtTelefone = useRef<HTMLInputElement>();
  const { FormRegistrarInfoYupValidation } = useFormRegistrarInfoValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<PessoaInfoModel>({
    resolver: yupResolver(FormRegistrarInfoYupValidation),
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });


  const onSubmit = (form: PessoaInfoModel) => {
    const model = picker<PessoaInfoModel>(form, new PessoaInfoModel());
    props.onSubmit(model);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset(new PessoaInfoModel());
      if (!isMobile) txtTelefone?.current?.focus();
    },
    fillForm: (model: PessoaInfoModel) => {
      reset({ ...model });
      if (!isMobile) txtTelefone?.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container style={{ maxWidth: '100%' }} direction='column' justifyContent='center'>
            <Grid item xs={12} style={{ marginBottom: 8 }}>
              <Controller
                name="telefone"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TELEFONE"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    inputRef={txtTelefone}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="Telefone de Contato"
                    placeholder="Digite seu telefone celular"
                    error={Boolean(errors.telefone && errors.telefone.message)}
                    helperText={
                      touchedFields.telefone || errors.telefone
                        ? errors.telefone?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box
                mb={2}
              >
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="EMAIL"
                      allowSubmit
                      disabled={props.loading}
                      showStartAdornment={true}
                      fullWidth={true}
                      autoComplete={'off'}
                      label="Email"
                      placeholder="Digite seu e-mail"
                      error={Boolean(errors.email && errors.email.message)}
                      helperText={
                        touchedFields.email || errors.email
                          ? errors.email?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});