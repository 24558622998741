import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { DesfazerIcon, FiltroIcon, MenuIcon } from "views/components/icons";
import { PesquisaMinhasIndicacoes } from "./components/pesquisa-minhas-indicacoes/pesquisa-minhas-indicacoes";
import { useMenuPrincipal } from "services/app";
import { useThemeQueries } from "views";
import { MinhasIndicacoesListSearchProps } from "../minhas-indicacoes-list/minhas-indicacoes-list-search-props";

export interface MinhasIndicacoesHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: MinhasIndicacoesListSearchProps) => any;
}

export const MinhasIndicacoesHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
}: MinhasIndicacoesHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu]
  );

  const rightArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ),
    [setOpenPesquisa, openPesquisa]
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: MinhasIndicacoesListSearchProps) => {
      setSearchProps(props);
    },
    [setSearchProps]
  );

  const pesquisa = (
    <PesquisaMinhasIndicacoes
      isOpened={openPesquisa!}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  );

  return (
    <>
      <PrivatePageHeader
        title="Minhas Indicações"
        leftArea={leftArea()}
        bottomArea={pesquisa}
        rightArea={rightArea()}
      />
    </>
  );
};
