import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";

export const podeVerPerguntasInternas: EnumTipoPessoas[] = [
    EnumTipoPessoas.SoftwareHouse,
    EnumTipoPessoas.GerenteComercial,
    EnumTipoPessoas.Revendedor,

]

export const podeResponderPerguntasIndicacao: EnumTipoPessoas[] = [
    EnumTipoPessoas.SoftwareHouse,
    EnumTipoPessoas.Revendedor,
    EnumTipoPessoas.FuncionarioFinanceiro,
    EnumTipoPessoas.Representante,
    EnumTipoPessoas.GerenteComercial,
    EnumTipoPessoas.AgenteExterno
]


export const podeResponderPerguntasInternas: EnumTipoPessoas[] = [
    EnumTipoPessoas.SoftwareHouse,
]
