import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import {
  Box,
  Button,
  Card,
  Fade,
  Grid,
  Typography,
} from '@material-ui/core';
import { useSessaoAtual } from 'services/app/providers';
import { FormLogin } from 'views/components/form/master/login/form-login';
import { LoginFormModel } from 'model/app';
import { CircularLoading } from 'views';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import { LoginIcon, VoltarIcon } from 'views/components/icons';
import { useToastSaurus } from 'services/app';
import { makeUtilClasses } from '../../../theme/util-make-styles';
import { useStyles } from './login-page-styles'

import Logo from '../../../../assets/img/logo-licenciamento.svg'
import { useGetValidarCadastro } from 'data/api/gestao/pessoa/get-validar-cadastro';
import { stringNumeros } from 'utils/string-numeros';
import { PublicPageHeader } from '../headers/header-public-page';

const LoginPage = () => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const { logar, carregando: carregandoSessao } = useSessaoAtual();
  const [bloqueado, setBloqueado] = useState(false);
  const loginRefs = useRef<DefaultFormRefs<LoginFormModel>>(null);
  const [fase, setFase] = useState<1 | 2>(1)
  const [mostrarFases, setMostrarFases] = useState<1 | 2>(1)
  const { getValidarCadastro, carregando: carregandoGet } = useGetValidarCadastro()

  const carregando = carregandoGet || carregandoSessao

  useEffect(() => {
    if (fase === 2 && mostrarFases !== 2) {
      setTimeout(() => {
        setMostrarFases(2)
      }, 300)
    } else if (fase === 1 && mostrarFases !== 1) {
      setTimeout(() => {
        setMostrarFases(1)
      }, 300)
    }

  }, [fase, mostrarFases, setMostrarFases])

  useEffect(() => {
      if (loginRefs) loginRefs.current?.fillForm(new LoginFormModel('', ''));
    }, []);

  const validarDocumento = async (cpfCnpj: string) => {
    const res = await getValidarCadastro(stringNumeros(cpfCnpj))

    if (res.erro) throw res.erro

    return res.resultado?.data ?? false
  }

  const efetuarLogin = async (model: LoginFormModel) => {
    try {
      if (fase === 1) {
        const res = await validarDocumento(model.cpfCnpj)
        if (res) {
          return setFase(2)
        } else {
          return history.push({
            pathname: '/registrar',
            state: {
              cpfCnpj: model.cpfCnpj
            }
          })
        }
      }
      await logar(stringNumeros(model.cpfCnpj), model.senha);
      setBloqueado(true);
      history.push('/');
    } catch (e: any) {
      showToast('error', e.message);
      loginRefs.current?.resetForm();
    }
  };

  return (
    <>
      <PublicPageHeader topOpacity='0.8' />
      <div className={`${utilClasses.contentCenter} ${classes.rootPadding}`}>
        <img src={Logo} alt='Logotipo Licenciamento' className={classes.logo} />
        <Grid container className={utilClasses.rootContainer}>
          {carregando || bloqueado ? (
            <CircularLoading tipo="FULLSIZED" />
          ) : null}
          <Grid item xs={12}>
            <Card className={classes.loginCard}>
              <Grid container direction="column">
                <Grid item xs={12} className={classes.textContainer}>
                  <Typography align="left" variant="h4" color="primary">
                    Login
                  </Typography>
                  <Typography variant='body1' color='textSecondary'>
                    Por favor, entre digitando as informações abaixo para poder continuar.
                    Se caso não tenha um cadastro, iremos validar e redicionaremos para página de cadastro.
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ width: '100%' }}>
                  <FormLogin
                    mostrarFase={mostrarFases}
                    showLoading={false}
                    ref={loginRefs}
                    loading={carregando}
                    fase={fase}
                    setFase={setFase}
                    onSubmit={(model) => {
                      efetuarLogin(model);
                    }}
                  />
                  <Box mt={7}>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth={true}
                        disabled={carregando || bloqueado}
                        onClick={() => {
                          loginRefs.current?.submitForm();
                        }}
                      >
                        <LoginIcon tipo="BUTTON_PRIMARY" />
                        Acessar
                      </Button>
                    </Grid>
                  </Box>
                  {fase === 2 && (
                    <Fade in={fase === 2}>
                      <Box mt={2}>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            color="primary"
                            variant="outlined"
                            size="large"
                            fullWidth={true}
                            disabled={carregando || bloqueado}
                            onClick={() => {
                              setFase(1)
                            }}
                          >
                            <VoltarIcon tipo="BUTTON" />
                            Voltar
                          </Button>
                        </Grid>
                      </Box>
                    </Fade>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LoginPage;
