import { Box, Button, Divider, Grid } from '@material-ui/core';
import { useRef } from 'react';
import { useStyles } from './form-registrar-styles';
import { useFormRegistrarValidation } from './form-registrar-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegistrarFormModel } from 'model/app/forms/master/registrar-form';

export const FormRegistrar = React.forwardRef<
  DefaultFormRefs<RegistrarFormModel>,
  DefaultFormProps<RegistrarFormModel>
>(({ ...props }: DefaultFormProps<RegistrarFormModel>, refs) => {
  const txtEmail = useRef<HTMLInputElement>();
  const { FormRegistrarYupValidation } = useFormRegistrarValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues
  } = useForm<RegistrarFormModel>({
    resolver: yupResolver(FormRegistrarYupValidation),
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });


  const onSubmit = (form: RegistrarFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({ nomeComercial: getValues('nomeComercial'), senha: '', confirmarSenha: '' });
      if (!isMobile) txtEmail?.current?.focus();
    },
    fillForm: (model: RegistrarFormModel) => {
      reset({ ...model });
      if (!isMobile) txtEmail?.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container style={{ maxWidth: '100%' }} direction='column' justifyContent='center'>
            <Grid item xs={12} style={{ marginBottom: 8 }}>
              <Controller
                name="nomeComercial"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    inputRef={txtEmail}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="Nome"
                    placeholder="Digite seu nome"
                    error={Boolean(errors.nomeComercial && errors.nomeComercial.message)}
                    helperText={
                      touchedFields.nomeComercial || errors.nomeComercial
                        ? errors.nomeComercial?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box
                mb={2}
              >
                <Controller
                  name="senha"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="PASSWORD"
                      allowSubmit
                      disabled={props.loading}
                      showStartAdornment={true}
                      fullWidth={true}
                      autoComplete={'off'}
                      label="Senha"
                      placeholder="Digite sua Senha"
                      error={Boolean(errors.senha && errors.senha.message)}
                      helperText={
                        touchedFields.senha || errors.senha
                          ? errors.senha?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                mb={Boolean(touchedFields.senha && errors.senha) ? 0 : 1.5}
              >
                <Controller
                  name="confirmarSenha"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="PASSWORD"
                      allowSubmit
                      disabled={props.loading}
                      showStartAdornment={true}
                      fullWidth={true}
                      autoComplete={'off'}
                      label="Repetir Senha"
                      placeholder="Confirme sua senha"
                      error={Boolean(errors.confirmarSenha && errors.confirmarSenha.message)}
                      helperText={
                        touchedFields.confirmarSenha || errors.confirmarSenha
                          ? errors.confirmarSenha?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
