import { KeyValueModel } from "model";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";

export const StatusGeralIndicacoMock: KeyValueModel[] = [
    new KeyValueModel(EnumStatusIndicacao.Disponivel, 'Disponivel'),
    new KeyValueModel(EnumStatusIndicacao.InteracaoIniciada, 'Interação Iniciada'),
    new KeyValueModel(EnumStatusIndicacao.RetornoInterno, 'Retorno Interno'),
    new KeyValueModel(EnumStatusIndicacao.ContatoRealizado, 'Contato Realizado'),
    new KeyValueModel(EnumStatusIndicacao.VisitaMarcada, 'Visita Marcada'),
    new KeyValueModel(EnumStatusIndicacao.VisitaRealizada, 'Visita Realizada'),
    new KeyValueModel(EnumStatusIndicacao.PropostaEnviada, 'Proposta Enviada'),
    new KeyValueModel(EnumStatusIndicacao.PropostaRecusada, 'Proposta Recusada'),
    new KeyValueModel(EnumStatusIndicacao.PropostaAceita, 'Proposta Aceita'),
    new KeyValueModel(EnumStatusIndicacao.AguardandoValidacao, 'Aguardando Validação'),
    new KeyValueModel(EnumStatusIndicacao.CancelamentoSolicitado, 'Cancelamento Solicitado'),
    new KeyValueModel(EnumStatusIndicacao.InvalidezSolicitada, 'Invalidez Solicitada'),
    new KeyValueModel(EnumStatusIndicacao.Finalizado, 'Finalizado'),
    new KeyValueModel(EnumStatusIndicacao.Cancelado, 'Cancelado'),
    new KeyValueModel(EnumStatusIndicacao.Invalidado, 'Inválido')
]

export const StatusIndicacaoEmAndamentoMock: KeyValueModel[] = [
    new KeyValueModel(EnumStatusIndicacao.RetornoInterno, 'Retorno Interno'),
    new KeyValueModel(EnumStatusIndicacao.ContatoRealizado, 'Contato Realizado'),
    new KeyValueModel(EnumStatusIndicacao.VisitaMarcada, 'Visita Marcada'),
    new KeyValueModel(EnumStatusIndicacao.VisitaRealizada, 'Visita Realizada'),
    new KeyValueModel(EnumStatusIndicacao.PropostaEnviada, 'Proposta Enviada'),
]

export const StatusIndicacaoValidacaoMock: KeyValueModel[] = [
    new KeyValueModel(EnumStatusIndicacao.AguardandoValidacao, 'Aguardando Validação'),
    new KeyValueModel(EnumStatusIndicacao.PropostaRecusada, 'Proposta Recusada'),
    new KeyValueModel(EnumStatusIndicacao.PropostaAceita, 'Proposta Aceita'),
    new KeyValueModel(EnumStatusIndicacao.CancelamentoSolicitado, 'Cancelamento Solicitado'),
    new KeyValueModel(EnumStatusIndicacao.InvalidezSolicitada, 'Invalidez Solicitada'),
]

export const StatusIndicacaoFinalizadaMock: KeyValueModel[] = [
    new KeyValueModel(EnumStatusIndicacao.Finalizado, 'Finalizado'),
    new KeyValueModel(EnumStatusIndicacao.Cancelado, 'Cancelado'),
]