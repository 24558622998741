import { EnumStatusGeralIndicacao } from "model/enums/enum-status-geral-indicacao";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";
import { guidEmpty } from "utils/guid-empty";

export class IndicacaoModel {
    constructor(
        public id: string = guidEmpty(),
        public responsavel: ResponsavelOrClienteOrIndicador = new ResponsavelOrClienteOrIndicador(),
        public cliente: ResponsavelOrClienteOrIndicador = new ResponsavelOrClienteOrIndicador(),
        public indicador: ResponsavelOrClienteOrIndicador = new ResponsavelOrClienteOrIndicador(),
        public sistema: Sistema = new Sistema(),
        public contato: string = '',
        public data: Date = new Date(),
        public nivelDeInteresse: number = 0,
        public instrucao: string = '',
        public observacaoFinalizacao: string = '',
        public status: EnumStatusIndicacao = EnumStatusIndicacao.Disponivel,
        public statusGeral: EnumStatusGeralIndicacao = EnumStatusGeralIndicacao.Disponivel,
        public avaliacaoIndicador: number = 0,
        public motivoAvaliacaoIndicador: string = '',
        public avaliacaoResponsavel: number = 0,
        public motivoAvaliacaoResponsavel: string = '',
        public dataUltimoRetorno: Date = new Date(),
        public dataUltimaVisita: Date = new Date(),
        public dataFinalizacao: Date = new Date(),
    ) { }
}

export class ResponsavelOrClienteOrIndicador {
    constructor(
        public id: string = guidEmpty(),
        public tipo: number = 0,
        public nomeComercial: string = '',
        public responsavel: string = '',
        public cpfCnpj: string = '',
        public status: number = 0,
        public revendaId: string = guidEmpty(),
        public revendaNome: string = '',
        public informacoes: Informacoes = new Informacoes(),
    ) { }
}
export class Informacoes {
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string = guidEmpty(),
        public xNome: string = '',
        public email: string = '',
        public telefone: string = '',
        public informacao: string = '',
        public fantasia: string = '',
        public logradouro: string = '',
        public cep: string = '',
        public numero: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public codMunicipio: number = 0,
        public uf: string = '',
        public complemento: string = '',
        public isComercial: boolean = false,
        public isJuridico: boolean = false,
        public isFinanceiro: boolean = false,
        public latitude: number = 0,
        public longitude: number = 0,
        public ie: string = '',
        public cnae: string = '',
        public crt: number = 0,
        public segmentoId: string = guidEmpty(),
        public obs: string = '',
        public dataAberturaEmpresa: string = '',
    ) { }
}
export class Sistema {
    constructor(
        public id: string = guidEmpty(),
        public nome: string = '',
        public descricao: string = '',
        public situacao: number = 0,
        public urlImagem: string = '',
        public qtdModulos: number = 0,
    ) { }
}
