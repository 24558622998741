import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ClienteIdealPerguntaModel } from 'model/api/gestao/pessoa-cliente-ideal/cliente-ideal-pergunta-model';

interface ClienteIdealPerguntaGetModel {
    maxPontos: number;
    perguntas: ClienteIdealPerguntaModel[];
}

export function useGetClienteIdeal() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ClienteIdealPerguntaGetModel>();

    const getClienteIdeal = useCallback(
        () =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/cliente-ideal`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getClienteIdeal,
    };
}
