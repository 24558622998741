import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    enderecoContainer: {
        background: '#F8F8F8',
        padding: '1rem',
        borderRadius: '15px',
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        '& .MuiFormControl-root': {
            background: '#fff'
        },
        [theme.breakpoints.down('md')]: {
            padding: '10px',
            marginRight: 0
        }
    },
    enderecoTitle: {
        borderBottom: '2px solid #E0E0E0',
        marginBottom: 32,
        '& h5': {
            fontWeight: 500
        }
    },
    tipoContainer: {
        background: '#F8F8F8',
        padding: '.5rem 1rem',
        borderRadius: '15px',
        marginTop: theme.spacing(2)
    }
}))