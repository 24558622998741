import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { IndicacaoPostModel } from 'model/api/gestao/indicacao/indicacao-post-model';
import { IndicacaoModel } from 'model/api/gestao/indicacao/indicacao-model';

export function usePostIndicacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<IndicacaoModel>();

    const postIndicacao = useCallback(
        (pagamento: IndicacaoPostModel) =>
            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/Indicacao`,
                method: 'POST',
                data: pagamento,
                enviarTokenUsuario: true,
                timeout: 100000
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIndicacao,
    };
}
