import { makeUseAxios, Options } from "axios-hooks";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { merge } from "lodash";
import LRU from "lru-cache";
import { VariaveisAmbiente } from "../../../config";
import {
  GestaoStorageKeys,
  useGestaoStorage,
} from "../../../services/app/use-cases/gestao-storage";
import { ApiBaseProps } from "./api-base-props";
import { ApiBaseResponse, EnumRetornoApiBase } from './api-base-response';
import { RetornoApiModel } from "../../../model";
import { useSessaoAtual } from "services/app";

type RequestParams = AxiosRequestConfig & {
  enviarTokenUsuario: boolean;
};

// type Validacoes = {
//   propriedade: string
//   mensagem: string
// }

// type ErrorSaurus = {
//   type: string
//   title: string
//   status: number
//   detail: string
//   instance: string
//   validacoes: Validacoes[]
//   urlRedirect: string
//   options: string
// }

const defaultTimeout = 10 * 1000; /* 10 Segundos */
const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: `${VariaveisAmbiente.apiUrl}/api`,
    timeout: defaultTimeout,
    timeoutErrorMessage:
      "O servidor não respondeu a solicitação, tente novamente mais tarde.",
  }),
  cache: new LRU({ max: 10 }),
});


export function useApiBase<T = any>(props?: ApiBaseProps) {

  const { logar } = useSessaoAtual()
  const { getRegistro } = useGestaoStorage();
  const { config, opcoes } = props || {};
  const [{ loading: carregandoAxios }, invocarAxios] = useAxios<any>(
    config || "",
    merge({ manual: true }, opcoes)
  );

  const [carregandoInterno, setCarregandoInterno] = useState(false);

  useEffect(() => {
    setCarregandoInterno(carregandoAxios);
  }, [carregandoAxios]);

  const invocarApi = useCallback(async (cfg?: RequestParams, options?: Options, tentarNovamente: boolean = true): Promise<RetornoApiModel<T>> => {
    const objRet = { tipoRetorno: EnumRetornoApiBase.Servidor, statusCode: 0 };
    let resultado: AxiosResponse<any> | undefined;
    let erro: Error | undefined;

    resultado = undefined;
    erro = undefined;

    const tokenStorage = getRegistro(GestaoStorageKeys.Token, false);
    const dadosUsuario = getRegistro(GestaoStorageKeys.UserData, false)
    const dadosAtob = () => {
      try {
        return atob(dadosUsuario).split("|")
      } catch {
        return ''
      }
    }
    const usuario = dadosAtob()[0]
    const senha = dadosAtob()[1]

    try {
      const tokenEnvio = cfg?.enviarTokenUsuario
        ? `Bearer ${tokenStorage}`
        : null;

      resultado = await invocarAxios(
        {
          ...cfg,
          headers: {
            Authorization: tokenEnvio,
            'FrontUrl': VariaveisAmbiente.frontUrl,
            ...cfg?.headers,
          },
        },
        options
      );

      const { tipoRetorno: tpRetorno, statusCode: retStatus } = ApiBaseResponse(undefined, resultado);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpRetorno;

    } catch (e: Error | any) {
      erro = e;

      const { tipoRetorno: tpRetorno, statusCode: retStatus } = ApiBaseResponse(e, e.response);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpRetorno;

      //TRATAMENTO DE ERRO DE API
      if (e.response && e.response?.data?.title) {
        if (e.response?.data?.errors) {
          if (e.response?.data?.errors?.Mensagens.length > 1) {
            const erroFormatado = e.response?.data?.errors?.Mensagens.join('. ');
            erro = new Error(erroFormatado);
          } else {
            erro = new Error(e.response?.data?.errors?.Mensagens[0]);
          }
        } else {
          erro = new Error(e.response?.data?.title);
        }
      }
      switch (objRet.tipoRetorno) {
        case EnumRetornoApiBase.Local:
          let msg = erro?.message?.toString() || "";
          if (msg.indexOf("timeout") > -1 && msg.indexOf("exceeded") > -1) {
            erro = new Error(
              `O servidor demorou muito para processar a requisição (${(cfg?.timeout || defaultTimeout) / 1000
              }s).`
            );
          }
          else if (msg.toLowerCase().indexOf("network error") > -1) {
            erro = new Error(
              `A comunicação com o servidor foi perdida. Tente novamente em alguns instantes.` +
              (msg.length > 0 ? `Detalhe: ${msg}` : ``)
            );
          }
          break;
        case EnumRetornoApiBase.Api:
          if (retStatus === 401) {
            erro = new Error(
              `Sua sessão expirou. Realize o login novamente para continuar. (401)`
            );
            await logar(usuario, senha)

            return await invocarApi(cfg, options);
          }
          break;
        case EnumRetornoApiBase.Servidor:
          erro = new Error(
            `O procedimento solicitado causou um erro no servidor. Tente novamente em alguns instantes. Detalhe: ${erro?.message}`
          );
          break;
      }
    }
    setCarregandoInterno(false);

    return {
      resultado,
      erro,
      tipoRetorno: objRet.tipoRetorno,
      statusCode: objRet.statusCode,
    };
  },
    [getRegistro, invocarAxios, logar]
  );

  return {
    invocarApi,
    carregando: carregandoInterno,
  };
}
