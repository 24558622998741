import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { IndicacaoPutModel } from 'model/api/gestao/indicacao/indicacao-put-model';
import { IndicacaoAvaliacaoPutModel } from 'model/api/gestao/indicacao/indicacao-avaliacao-put-model';

export function usePutIndicacao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putIndicacao = useCallback(
        (indicacao: IndicacaoPutModel | IndicacaoAvaliacaoPutModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Indicacao`,
                method: 'PUT',
                data: indicacao,
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putIndicacao,
    };
}
