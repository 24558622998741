import { Route, Switch } from 'react-router-dom';
import LogoutPage from 'views/pages/public/logout/logout-page';
import LoginPage from 'views/pages/public/login/login-page';
import RouterMain from './components/router-main/router-main';
import { ExpiredTokenRoute } from './components/expired-token-route';
import { RecoilRoot } from 'recoil';
import { GlobalStatesProvider } from 'services/app/providers/global-states-provider/global-states-provider';
import { useMemo } from 'react';
import { CadastrosProvider } from 'services/app';
import RegistrarPage from 'views/pages/public/registrar/registrar-page';

export const RouterApp = () => {
  const switchApp = useMemo(() =>
    <Switch>
      <Route exact path="/logout" component={LogoutPage} />
      <Route
        exact
        path="/login/:parametro?"
        component={LoginPage}
      />
      <Route
        exact
        path="/registrar"
        component={RegistrarPage}
      />
      <ExpiredTokenRoute>
        <RouterMain />
      </ExpiredTokenRoute>
    </Switch>
    , []);
  return (
    <GlobalStatesProvider>
      <RecoilRoot>
        <CadastrosProvider>
          {switchApp}
        </CadastrosProvider>
      </RecoilRoot>
    </GlobalStatesProvider>
  );
};
