
import { useMemo } from 'react';
import { useValidationYupSenha, useValidationYupSenhaConfirmacao } from 'views/components/form-validations';
import * as Yup from 'yup';

export const useFormPessoaSenhaValidation = () => {

    const { senhaYup } = useValidationYupSenha()
    const { senhaConfirmacaoYup } = useValidationYupSenhaConfirmacao()

    const FormPessoaSenhaValidationYup = useMemo(() => {
        return Yup.object().shape({
            novaSenha: senhaYup(),
            repetirSenha: senhaConfirmacaoYup('novaSenha')
        });
    }, [senhaConfirmacaoYup, senhaYup])

    return {
        FormPessoaSenhaValidationYup,
    }
}

