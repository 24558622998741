
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormIndicacaoEmpresaValidation = () => {


  const FormIndicacaoEmpresaValidationYup = useMemo(() => {
    return Yup.object().shape({
      fantasia: Yup.string().required('A razão social é obrigatória.'),
      faturamentoEstimado: Yup.string().required('O valor de faturamento é obrigatório.')
      .test("valorFaturanmentoEstimado", "O valor de faturamento não pode ser negativo.", val => {
        if (val && val.includes('-')) {
          return false
        }
        return true
      }),
      cep: Yup.string().required('O cep é obrigatório.'),
      logradouro: Yup.string().required('O endereço é obrigatório.'),
      numero: Yup.string().required('O número é obrigatório.'),
      bairro: Yup.string().required('O bairro é obrigatório.'),
      municipio: Yup.string().required('O município é obrigatório.'),
      complemento: Yup.string().notRequired(),
      codMunicipio: Yup.number().notRequired().typeError('O Cód Municipio é obrigatório'),
    });
  }, [])

  return {
    FormIndicacaoEmpresaValidationYup,
  }
}

