import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";
import { useCallback } from "react";
import { useThemeQueries } from "views";

export function useRetornarCorStatus() {

    const { theme } = useThemeQueries();

    const retornarCorStatus = useCallback((status: EnumStatusIndicacao): string => {
        switch (status) {
            case EnumStatusIndicacao.Disponivel:
                return '#464EB8'
            case EnumStatusIndicacao.InteracaoIniciada:
                return '#D7096C'
            case EnumStatusIndicacao.RetornoInterno:
                return theme.palette.error.dark
            case EnumStatusIndicacao.ContatoRealizado:
                return '#00A3FF'
            case EnumStatusIndicacao.VisitaMarcada:
                return theme.palette.warning.main
            case EnumStatusIndicacao.VisitaRealizada:
                return '#195BA8'
            case EnumStatusIndicacao.PropostaEnviada:
                return '#C3AC6C'
            case EnumStatusIndicacao.PropostaAceita:
                return theme.palette.success.main
            case EnumStatusIndicacao.PropostaRecusada:
                return '#8F00FF'
            case EnumStatusIndicacao.InvalidezSolicitada:
                return theme.palette.error.dark
            case EnumStatusIndicacao.Cancelado:
                return theme.palette.error.main
            case EnumStatusIndicacao.Finalizado:
                return theme.palette.success.dark
            case EnumStatusIndicacao.CancelamentoSolicitado:
                return theme.palette.error.dark
            case EnumStatusIndicacao.Invalidado:
                return theme.palette.error.dark
            case EnumStatusIndicacao.AguardandoValidacao:
                return '#41c5d1'
        }
        return theme.palette.primary.main
    }, [theme.palette.error.dark, theme.palette.error.main, theme.palette.primary.main, theme.palette.success.dark, theme.palette.success.main, theme.palette.warning.main]);

    return {
        retornarCorStatus
    };
}