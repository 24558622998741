import { Box, Button, Card, Grid, Slide, Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useGetInteracoes, usePostInteracoes } from "data/api/gestao/interacoes";
import { InteracoesModel } from "model/api/gestao/interacoes/interacoes-model";
import { InteracoesPostModel } from "model/api/gestao/interacoes/interacoes-post-model";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { picker } from "utils/picker";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { CircularLoading, LoadingButton } from "views/components/utils";
import { useStyles } from "./indicacao-interacao-styles";
import { InteracaoChat } from "./components/interacao-chat/interacao-chat";
import { EnviarMensagemIcon } from "views/components/icons/enviar-mensagem-icon";
import { isEmpty } from "lodash";
import { toDateString, toDateStringApi } from "utils/to-date";
import { useThemeQueries } from "views/theme";
import { EnumStatusIndicacao } from "model/enums/enum-status-indicacao";

type Props = {
    abaSelecionada: boolean
    id: string
}

export const IndicacaoInteracao = ({ abaSelecionada, id }: Props) => {
    //AUX
    const modalClasses = useModalStyles();
    const classes = useStyles()
    const { showToast } = useToastSaurus();
    const { theme } = useThemeQueries();

    //CHAMADAS DA API
    const { postInteracoes, carregando: carregandoPost } = usePostInteracoes()
    const { getInteracoes, carregando: carregandoGet } = useGetInteracoes()

    //STATES E REFS
    const contentFormsRef = useRef<any>()
    const scrollRef = useRef<HTMLElement | null>(null)
    const [interacoes, setInteracoes] = useState<InteracoesModel[]>([])

    //PROVIDERS
    const { usuario } = useSessaoAtual()

    const validarStatus = useCallback(() => {
        const statusFinalizado = interacoes.filter(x => x.indicacaoId === id).filter(x => x.tipoInteracao >= EnumStatusIndicacao.PropostaRecusada);

        if (isEmpty(statusFinalizado)) return true

        return false
    }, [id, interacoes])

    const getInteracoesWrapper = useCallback(async () => {
        try {
            const res = await getInteracoes(id, 1, `pageSize=0`)

            if (res.erro) {
                throw res.erro
            }

            if (!res.resultado) {
                return
            }

            setInteracoes(res.resultado.data.list)

            scrollRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getInteracoes, id, showToast])

    const {
        handleSubmit,
        reset,
        control,
    } = useForm<InteracoesPostModel>({

    })

    const enviarMensagem = useCallback(async (value: InteracoesPostModel) => {
        try {
            if (isEmpty(value.descricao)) {
                return showToast('error', 'Você não pode enviar uma mensagem vazia.')
            }

            if (carregandoPost) return

            const interacaoPicker = picker<InteracoesPostModel>(value, new InteracoesPostModel())

            interacaoPicker.indicacaoId = id
            interacaoPicker.responsavelId = usuario?.Id ?? ''
            interacaoPicker.tipoInteracao = EnumStatusIndicacao.RetornoInterno;
            interacaoPicker.dataHora = toDateStringApi(new Date())

            reset(new InteracoesPostModel())

            const res = await postInteracoes(id, interacaoPicker)

            if (res.erro) throw res.erro

            await getInteracoesWrapper()

        } catch (err: any) {
            showToast('error', `Não foi possível enviar a mensagem pela seguinte razão: ${err.message}`)
        }
    }, [carregandoPost, getInteracoesWrapper, id, postInteracoes, reset, showToast, usuario?.Id])


    useEffect(() => {
        if (abaSelecionada) {
            getInteracoesWrapper()
        }
    }, [abaSelecionada, getInteracoesWrapper])

    useEffect(() => {
        contentFormsRef.current.scrollIntoView({ behavior: 'auto' })
    }, [interacoes])

    const switchDiaSemana = useCallback((dia: number) => {
        switch (dia) {
            case 0:
                return 'Domingo'
            case 1:
                return 'Segunda-Feira'
            case 2:
                return 'Terça-Feira'
            case 3:
                return 'Quarta-Feira'
            case 4:
                return 'Quinta-Feira'
            case 5:
                return 'Sexta-Feira'
            case 6:
                return 'Sabádo'
        }
    }, [])

    const validarDiasInteracao = useCallback((dataInteracao: string) => {

        var dataString = dataInteracao;
        var partesDaData = dataString.split('/');
        var dataFormatoAmericano = partesDaData[1] + '/' + partesDaData[0] + '/' + partesDaData[2];

        const dataAtual = new Date();
        const dataOntem = new Date();
        const dataLimite = new Date();
        const dataValidar = new Date(dataFormatoAmericano);

        const diaSemana = dataValidar.getDay();

        dataOntem.setDate(dataOntem.getDate() - 1);
        dataLimite.setDate(dataLimite.getDate() - 7);

        if (dataValidar.getFullYear() === dataAtual.getFullYear() && dataValidar.getMonth() === dataAtual.getMonth() &&
            dataValidar.getDate() === dataAtual.getDate()) {
            return 'Hoje';
        }
        else if (dataValidar.getFullYear() === dataOntem.getFullYear() && dataValidar.getMonth() === dataOntem.getMonth() &&
            dataValidar.getDate() === dataOntem.getDate()) {
            return 'Ontem';
        }
        else if (dataValidar >= dataLimite && dataValidar <= dataAtual) {
            return switchDiaSemana(diaSemana);
        }
        else {
            return `${switchDiaSemana(diaSemana)}, ${dataInteracao}`;
        }
    }, [switchDiaSemana])

    const formataArray = interacoes.map(x => {
        return {
            ...x,
            data: toDateString(x.dataHora) ?? ''
        }
    })

    const interacoesPorData = Array.from(new Set<string>(formataArray.map(x => x.data)))

    return (
        <div className={modalClasses.content} >
            <Box position={"relative"} width="100%">
                <div className={classes.loading}>
                    <Slide unmountOnExit mountOnEnter in={carregandoGet} direction="down">
                        <div>
                            <CircularLoading tipo="NORMAL" />
                        </div>
                    </Slide>
                </div>
            </Box>
            <div className={classNames(modalClasses.contentForms)}>
                <Box>
                    <Grid container>
                        {interacoesPorData.sort((a, b) => new Date(a).getTime() - new Date(b).getTime()).map((data) => {
                            return (
                                <>
                                    <Box width='100%' display='flex' justifyContent='center' style={{
                                        paddingBottom: '8px'
                                    }}>
                                        <div className={classes.containerBalao}>
                                            <Card style={{
                                                background: theme.palette.grey[200],
                                            }}>
                                                <Typography
                                                    variant="body2"
                                                    align="center"
                                                >
                                                    <Box p={1} fontWeight={500}>
                                                        {validarDiasInteracao(data)}
                                                    </Box>
                                                </Typography>
                                            </Card>
                                        </div>
                                    </Box>
                                    {interacoes.filter(interacao => toDateString(interacao.dataHora) === data).sort((a, b) => new Date(a.dataHora).getTime() - new Date(b.dataHora).getTime()).map((interacao, index, array) => (
                                        <InteracaoChat key={interacao.id} model={interacao} array={array} index={index} scrollRef={scrollRef}/>
                                    ))}
                                </>
                            )
                        })}
                        <div ref={contentFormsRef}></div>
                    </Grid>
                </Box>
            </div>

            {validarStatus() && (
                <div style={{
                    flex: '0 0 auto'
                }}>
                    <form onSubmit={handleSubmit(async (ev) => await enviarMensagem(ev))}>
                        <Grid container style={{
                            padding: '8px',
                        }}>
                            <Grid item xs={10}>
                                <Controller
                                    control={control}
                                    name="descricao"
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            fullWidth
                                            className={classes.chat}
                                            placeholder="Envie uma mensagem"
                                            {...field}
                                            allowSubmit
                                            tipo="TEXTO"
                                            onClick={() => {
                                                getInteracoesWrapper();
                                            }}
                                            autoComplete="off"
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disableElevation
                                    className={classes.btnEnviar}
                                >
                                    {
                                        carregandoPost
                                            ? (
                                                <Tooltip arrow title="Enviando mensagem">
                                                    <div>
                                                        <LoadingButton tipo="BRANCO" />
                                                    </div>
                                                </Tooltip>
                                            )
                                            : <EnviarMensagemIcon tipo="BUTTON_PRIMARY" />
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            )}
        </div >
    )
}