import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    cLoader: {
        animation: 'rotate 1s infinite',
        border: '3px solid rgba(31, 156, 228, 0.04)',
        margin: '0 4px',
        borderRadius: '100%',
        borderTopColor: '#FFF',
        height: '30px',
        width: '30px',
        padding: 4
    },
    loadAzul: {
        borderTopColor: theme.palette.primary.main,
    },
    loadBranco: {
        borderTopColor: '#FFF',
    },
    "@keyframes rotate": {
        "to": {
            transform: 'rotate(1turn)'
        }
    }
}));

