import { Button } from "@material-ui/core";
import { usePutPessoaClienteIdeal } from "data/api/gestao/cliente-ideal/put-pessoa-cliente-ideal";
import { usePostIndicacao } from "data/api/gestao/indicacao";
import { isEmpty } from "lodash";
import { IndicacaoModel } from "model/api/gestao/indicacao/indicacao-model";
import { IndicacaoPostModel } from "model/api/gestao/indicacao/indicacao-post-model";
import { ClienteIdealPerguntaModel } from "model/api/gestao/pessoa-cliente-ideal/cliente-ideal-pergunta-model";
import { PessoaClienteIdealPutModel } from "model/api/gestao/pessoa-cliente-ideal/pessoa-cliente-ideal-put-model";
import { FormStep } from "model/app";
import { IndicacaoDadosFormModel } from "model/app/forms/indicacao/indicacao-dados-form-model";
import { IndicacaoEmpresaFormModel } from "model/app/forms/indicacao/indicacao-empresa-form-model";
import { IndicacaoInteresseFormModel } from "model/app/forms/indicacao/indicacao-interesse-form-model";
import { EnumStepperIndicacao } from "model/enums/enum-stepper-indicacao";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { guidEmpty } from "utils/guid-empty";
import { stringNumeros } from "utils/string-numeros";
import { toDateStringApi } from "utils/to-date";
import { useFormStepper } from "views/components/form-stepper";
import { FormIndicacaoDados } from "views/components/form/master/indicacao/indicacao-cadastro/form-indicacao-dados-form/form-indicacao-dados";
import { FormIndicacaoEmpresa } from "views/components/form/master/indicacao/indicacao-cadastro/form-indicacao-empresa-form/form-indicacao-empresa";
import { FormIndicacaoInteresse } from "views/components/form/master/indicacao/indicacao-cadastro/form-indicacao-interesse-form/form-indicacao-interesse";
import { FormPerguntaRefs, FormPerguntas } from "views/components/form/master/indicacao/indicacao-cadastro/form-perguntas/form-perguntas";
import { DefaultFormRefs } from "views/components/form/utils";
import { AvancarIcon, EmpresaIcon, VoltarIcon } from "views/components/icons";
import { InstrucaoIcon } from "views/components/icons/instrucao-icon";
import { PessoaIcon } from "views/components/icons/pessoa-icon";

export const useIndicacaoCadastro = () => {
    const { currentStep, nextStep, prevStep } = useFormStepper(4);
    const [carregandoFromForms, setCarregandoFromForms] = useState(false);
    const { postIndicacao, carregando: carregandoPost } = usePostIndicacao()
    const { putPessoaClienteIdeal, carregando: carregandoPut } = usePutPessoaClienteIdeal();
    const { abrirCadastroIndicacao } = useCadastros()
    const { usuario } = useSessaoAtual()
    const { showToast } = useToastSaurus()

    const loading = carregandoFromForms || carregandoPost || carregandoPut

    const retTelaIndex = useCallback(
        (index: number): EnumStepperIndicacao | undefined => {
            switch (index) {
                case 0:
                    return EnumStepperIndicacao.Dados;
                case 1:
                    return EnumStepperIndicacao.Empresa;
                case 2:
                    return EnumStepperIndicacao.Interesse;
                case 3:
                    return EnumStepperIndicacao.Perguntas;
            }
            return undefined;
        }, []);

    //  Refs de formulário

    const refPreCadastroDados =
        useRef<DefaultFormRefs<IndicacaoDadosFormModel>>(null);
    const refPreCadastroEmpresa =
        useRef<DefaultFormRefs<IndicacaoEmpresaFormModel>>(null);
    const refPreCadastroInteresse =
        useRef<DefaultFormRefs<IndicacaoInteresseFormModel>>(null);
    const refPreCadastroPerguntas =
        useRef<FormPerguntaRefs>(null)

    const refDadosFormModel = useRef<IndicacaoDadosFormModel>(new IndicacaoDadosFormModel())
    const refEmpresaFormModel = useRef<IndicacaoEmpresaFormModel>(new IndicacaoEmpresaFormModel())
    const refInteresseFormModel = useRef<IndicacaoInteresseFormModel>(new IndicacaoInteresseFormModel())
    const refPerguntasModel = useRef<PessoaClienteIdealPutModel[]>([])

    const perguntasRef = useRef<ClienteIdealPerguntaModel[]>([])
    const indicacao = useRef<IndicacaoModel | null>(null)

    useEffect(() => {
        switch (retTelaIndex(currentStep)) {
            case EnumStepperIndicacao.Dados:
                refPreCadastroDados.current?.fillForm(refDadosFormModel.current);
                break;
            case EnumStepperIndicacao.Empresa:
                refPreCadastroEmpresa.current?.fillForm(refEmpresaFormModel.current);
                break;
            case EnumStepperIndicacao.Interesse:
                refPreCadastroInteresse.current?.fillForm(refInteresseFormModel.current);
                break;
            case EnumStepperIndicacao.Perguntas:
                refPreCadastroPerguntas.current?.fillForm(refPerguntasModel.current)
        }
    }, [currentStep, retTelaIndex]);

    const avancarStep = useCallback(() => {
        switch (retTelaIndex(currentStep)) {
            case EnumStepperIndicacao.Dados:
                refPreCadastroDados.current?.submitForm();
                break;
            case EnumStepperIndicacao.Empresa:
                refPreCadastroEmpresa.current?.submitForm();
                break;
            case EnumStepperIndicacao.Interesse:
                refPreCadastroInteresse.current?.submitForm();
                break;
            case EnumStepperIndicacao.Perguntas:
                if(refPreCadastroPerguntas.current?.isUltimaPergunta()){
                    refPreCadastroPerguntas.current.submitForm();
                    return
                }
                refPreCadastroPerguntas.current?.avancarPergunta();
        }
    }, [currentStep, retTelaIndex]);

    const voltarStep = useCallback(() => {
        if (retTelaIndex(currentStep) === EnumStepperIndicacao.Perguntas && !refPreCadastroPerguntas.current?.isPrimeiraPergunta()) {
            refPreCadastroPerguntas.current?.voltarPergunta();
            return
        }
        prevStep();
    }, [currentStep, prevStep, retTelaIndex]);

    const enviarRespostas = useCallback(async (indicacao: IndicacaoModel) => {
        try {
            const res = await putPessoaClienteIdeal(indicacao.cliente.id, refPerguntasModel.current.map(pergunta => {
                return {
                    ...pergunta,
                    pessoaId: indicacao.cliente.id
                }
            }).filter(pergunta => !isEmpty(pergunta.respostaId)));

            if (res.erro) throw res.erro

            abrirCadastroIndicacao(indicacao.id, '', true)
        } catch (e: any) {
            showToast('error', 'A indicação foi cadastrada, porém houve um erro ao enviar as avaliações. Tente Novamente')
        }
    }, [abrirCadastroIndicacao, putPessoaClienteIdeal, showToast])

    const finalizarCadastro = useCallback(async () => {
        try {
            const { email, cpfCnpj, contato, telefone } =
                refDadosFormModel.current
            const { bairro, cep, codMunicipio, complemento, fantasia, logradouro, municipio, numero, uf, faturamentoEstimado } =
                refEmpresaFormModel.current
            const { instrucao, nivelDeInteresse, sistemaId, responsavelId } = refInteresseFormModel.current
            const indicacaoObj: IndicacaoPostModel = {
                ...new IndicacaoPostModel(),
                email: email,
                cpfCnpj: stringNumeros(cpfCnpj),
                nomeComercial: fantasia,
                faturamentoEstimado: faturamentoEstimado,
                fantasia: fantasia,
                contato: contato,
                telefone: telefone,
                bairro: bairro,
                cep: cep,
                codMunicipio: codMunicipio,
                complemento: complemento,
                logradouro: logradouro,
                municipio: municipio,
                numero: numero,
                uf: uf,
                instrucao: instrucao,
                nivelDeInteresse: nivelDeInteresse,
                sistemaId: sistemaId,
                indicadorId: usuario?.Id ?? guidEmpty(),
                responsavelId: responsavelId,
                data: toDateStringApi(new Date())
            }

            const res = await postIndicacao(indicacaoObj)

            if (res.erro) {
                throw new Error(res.erro)
            }

            if (!res.resultado) return

            indicacao.current = res.resultado.data

            enviarRespostas(res.resultado.data)
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [enviarRespostas, postIndicacao, showToast, usuario?.Id])

    const refBtn = useRef<HTMLParagraphElement>(null)

    const cadastrarEditarButton = useMemo(() => {
        return (
            <Button
                disabled={loading}
                onClick={() => {
                    avancarStep();
                }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                innerRef={refBtn}
            >
                <AvancarIcon tipo='BUTTON_PRIMARY' />
                Avançar
            </Button>
        );
    }, [loading, avancarStep]);

    const voltarButton = useMemo(() => {
        return (
            <Button
                type="submit"
                color="primary"
                variant="outlined"
                size="large"
                fullWidth={true}
                disabled={loading}
                onClick={voltarStep}
            >
                <VoltarIcon tipo="BUTTON" />
                Voltar
            </Button>
        );
    }, [voltarStep, loading]);

    const avancarButton = useMemo(() => {
        return (
            <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                fullWidth={true}
                disabled={loading}
                onClick={avancarStep}
            >
                <AvancarIcon tipo="BUTTON_PRIMARY" />
                Próximo Passo
            </Button>
        );
    }, [loading, avancarStep]);

    const getFormPreCadastroDados = useCallback((): JSX.Element => {
        const handleSubmitFormPreCadastroDados = async (
            modelo: IndicacaoDadosFormModel,
        ) => {
            refDadosFormModel.current = modelo;
            nextStep();
        };
        return (
            <FormIndicacaoDados
                showLoading={true}
                ref={refPreCadastroDados}
                loading={loading}
                onSubmit={handleSubmitFormPreCadastroDados}
            />
        );
    }, [loading, nextStep]);

    const getFormPreCadastroEmpresa = useCallback((): JSX.Element => {
        const handleSubmitFormPreCadastroEmpresa = async (
            modelo: IndicacaoEmpresaFormModel,
        ) => {
            refEmpresaFormModel.current = modelo;
            nextStep();
        };
        return (
            <FormIndicacaoEmpresa
                showLoading={true}
                ref={refPreCadastroEmpresa}
                loading={loading}
                onSubmit={handleSubmitFormPreCadastroEmpresa}
                setCarregandoExterno={setCarregandoFromForms}
            />
        );
    }, [loading, nextStep]);

    const getFormPreCadastroInteresse = useCallback((): JSX.Element => {
        const handleSubmitFormPreCadastroInteresse = async (
            modelo: IndicacaoInteresseFormModel,
        ) => {
            refInteresseFormModel.current = modelo;
            nextStep();
            // await finalizarCadastro()
        };
        return (
            <FormIndicacaoInteresse
                showLoading={true}
                ref={refPreCadastroInteresse}
                loading={loading}
                onSubmit={handleSubmitFormPreCadastroInteresse}
            />
        );
    }, [loading, nextStep]);

    const getFormPreCadastroPerguntas = useCallback((): JSX.Element => {
        const handleSubmitFormPreCadastroPerguntas = async (
            modelo: PessoaClienteIdealPutModel[],
        ) => {
            refPerguntasModel.current = modelo;
            if (indicacao.current && !isEmpty(modelo)) {
                await enviarRespostas(indicacao.current)
                return
            }
            await finalizarCadastro()
        };
        return (
            <FormPerguntas
                showLoading={true}
                ref={refPreCadastroPerguntas}
                loading={loading}
                onSubmit={handleSubmitFormPreCadastroPerguntas}
                perguntas={perguntasRef.current}
            />
        );
    }, [enviarRespostas, finalizarCadastro, loading]);

    const getFormArray = useMemo(() => {
        const ret = [];
        let i = 0;
        while (retTelaIndex(i)) {
            const tela = retTelaIndex(i);
            switch (tela) {
                case EnumStepperIndicacao.Dados:
                    ret.push(
                        new FormStep(
                            'Dados',
                            'Informe abaixo os dados do cliente.',
                            <PessoaIcon tipo="GERAL" />,
                            'Dados',
                            <PessoaIcon tipo="GERAL" />,
                            getFormPreCadastroDados(),
                            voltarButton,
                            avancarButton,
                        ),
                    );
                    break;
                case EnumStepperIndicacao.Empresa:
                    ret.push(
                        new FormStep(
                            'Dados Comerciais',
                            'Informe os dados da empresa.',
                            <EmpresaIcon tipo="GERAL" />,
                            'Empresa',
                            <EmpresaIcon tipo="GERAL" />,
                            getFormPreCadastroEmpresa(),
                            voltarButton,
                            avancarButton,
                        ),
                    );
                    break;
                case EnumStepperIndicacao.Interesse:
                    ret.push(
                        new FormStep(
                            'Instrução',
                            'Informe alguma instrução que deseja.',
                            <InstrucaoIcon tipo="GERAL" />,
                            'Interesse',
                            <InstrucaoIcon tipo="GERAL" />,
                            getFormPreCadastroInteresse(),
                            voltarButton,
                            avancarButton,
                        ),
                    );
                    break;
                case EnumStepperIndicacao.Perguntas:
                    ret.push(
                        new FormStep(
                            'Avaliação',
                            '',
                            <InstrucaoIcon tipo='GERAL' />,
                            'Avaliação',
                            <InstrucaoIcon tipo="GERAL" />,
                            getFormPreCadastroPerguntas(),
                            voltarButton,
                            avancarButton
                        )
                    )
            }
            i++;
        }

        if (ret.length > 0) {
            ret[ret.length - 1].nextButton = cadastrarEditarButton;
            ret[0].previousButton = undefined;
        }

        return ret;
    }, [retTelaIndex, getFormPreCadastroDados, voltarButton, avancarButton, getFormPreCadastroEmpresa, getFormPreCadastroInteresse, getFormPreCadastroPerguntas, cadastrarEditarButton]);

    return {
        formStepper: {
            currentStep,
            nextStep,
            prevStep,
        },
        formArray: getFormArray,
        carregando: loading,
    }
}