import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  infoField: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    '& p': {
      fontSize: 13,
    },
  },
  btnAvancar: {
    '& svg': {
      margin: 0,
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(-1),
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.grey[300],
    paddingLeft: theme.spacing(1),
    borderLeftStyle: 'solid',
  },
  formartText: {
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  statusText: {
    borderRadius: theme.shape.borderRadius,
    padding: '5px 8px',
    textAlign: 'center',
    background: 'rgb(16, 200, 34)',
    minWidth: '130px',
    color: 'rgb(255, 255, 255)',
    marginRight: '8px',
    fontSize: '11px',
  },
  subText: {
    fontSize: '13px'
  },
  text: {
    fontSize: '12px'
  },

  cardRightButton: {
    width: "50px",
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.divider,
    margin: '5px 0px 5px 10px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.primary.main,
    },
  },
  container: {
    '& p': {
      fontSize: 13
    }
  },
  item: {
    marginLeft: 7,
    minWidth: 100,
    maxWidth: 200,
    [theme.breakpoints.down('md')]: {
      margin: '4px 0',
      marginRight: theme.spacing(2)
    }
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1.5),
      alignItems: 'flex-start'
    }
  },
  dividerMobile: {
    height: '2px',
  }
}));
