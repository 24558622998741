import * as Yup from 'yup';
import { useValidationYupDocumento, useValidationYupSenha } from '../../../form-validations'
export const useFormLoginValidation = (fase: 1 | 2) => {
    const { senhaYup } = useValidationYupSenha()
    const { documentoYup } = useValidationYupDocumento(false)
    const FormLoginYupValidation = Yup.object().shape({
        cpfCnpj: documentoYup(),
        senha: fase === 2 ? senhaYup() : Yup.string().notRequired(),
    });

    return {
        FormLoginYupValidation
    }
}

