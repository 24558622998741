
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormIndicacaoEdicaoValidation = () => {

  const FormIndicacaoEdicaoValidationYup = useMemo(() => {

    return Yup.object().shape({
      motivoAvaliacaoIndicador: Yup.string().required('O motivo é obrigatório.')
    });
  }, [])

  return {
    FormIndicacaoEdicaoValidationYup,
  }
}

